import React, { useCallback, useEffect, useState, useMemo } from "react";
import { arrowDown, filter, magnifyingGlass } from "../../theme/icons";
import AppIcon from "../../theme/appIcon/appIcon";
import "./appSelection.scss";
import AppIconButton from "../../theme/appIconButton/appIconButton";
import MotifAppInput from "../../theme/motifAppInput/motifAppInput";
import { useDispatch, useSelector } from "react-redux";
import * as actionsType from "../../store/actions/meta-actions";
import { AppClass } from "../appCard/models/appClass";
import AppCard from "../appCard/appCard";
import t from "../../localization/en/translation.json";
import { AppButton } from "../../theme";
import { APP_STATE } from "../../store/reducers";
import { gridAppPlatformKeys } from "../../containers/manageUsers/listUsers/listUserConstants";
import moment from "moment";
import { VisibleAppCounts } from "../../utils/Constants";
import { updateSelectedApps } from "../../store/actions/create-project-actions";
import { AppDetail } from "../createProject/models/appDetail";
import AppChipTypes from "../../theme/appChipType/appChipType";
import Loader from "../loader/loader";
import AppSelectionFilters from "../appSelectionFilters/appSelectionFilters";
import { getFilteredApps } from "../appSelectionFilters/helpers";

interface AppSelectionProps {
  createProjectEnabled: boolean;
  record: {
    createdOn: string;
    id: string;
    appDetails: AppDetail[];
  };
  isPortalAdmin: boolean;
  handleIsDirty: Function;
}

const AppSelection: React.FC<AppSelectionProps> = (props) => {
  const [search, setSearch] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [appCount, setAppCount] = useState(0);
  const apps = useSelector((state: APP_STATE) => state.meta.apps);
  const currentUser = useSelector((state: APP_STATE) => state.meReducer.currentUser);
  const activeFeatures = useSelector<APP_STATE, string[]>((state) => state.projects.activeFeatures);
  const activeApps = useSelector<APP_STATE, AppDetail[]>((state) => state.createProjectReducer.selectedApps);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>();
  const dispatch = useDispatch();

  const isSharePointAppSupported = useMemo(() => {
    return activeFeatures?.map((features) => features.toLowerCase()).includes(gridAppPlatformKeys.SHARE_POINT.toLowerCase()) || props.createProjectEnabled;
  }, [activeFeatures, props.createProjectEnabled]);

  const getAvailableApps = useCallback(
    (appsArr: AppClass[]) => {
      const { record, isPortalAdmin } = props;

      // ENABLING LA APPS ONLY WHEN EDIT PROJECT MODE AND USER HAS PROTAL ADMIN ROLE
      const allAppsList = (isEditMode && isPortalAdmin) ? appsArr : appsArr.filter(app => !app.isLimitedAvailable)
      return props.createProjectEnabled
        ? allAppsList
        : allAppsList.filter((app) => {
          const isAppAvailable = moment(record?.createdOn).diff(moment(app.createdOn), "days");
          return isAppAvailable > 0;
        });
    },
    [props, isEditMode]
  );

  const sortByPopularityRank = (apps) => {
    return apps.sort((a, b) => {
      const rankA = a.popularityRank == null ? Infinity : a.popularityRank;
      const rankB = b.popularityRank == null ? Infinity : b.popularityRank;
      return rankA - rankB;
    });
  }

  useEffect(() => {
    if (apps.length === 0) {
      dispatch(actionsType.getApps());
    }
    dispatch(actionsType.getBundles());
  }, [apps, dispatch]);

  const [appList, setAppList] = useState<AppClass[]>([]);

  useEffect(() => {
    const {filtredApps} = appliedFilters || {};
    let processedApps = filtredApps?.length ? filtredApps : apps;
    if (!isSharePointAppSupported) {
      processedApps = processedApps.filter((app) => app.key !== gridAppPlatformKeys.SHARE_POINT);
    }

    processedApps = sortByPopularityRank(getAvailableApps(processedApps));

    if (search) {
      processedApps = processedApps.filter((app) => app.name.toLowerCase().includes(search.toLowerCase()) || (app.description && app.description.toLowerCase().includes(search.toLowerCase())));
    }

    const visibleCount = showAll || (search && search.length > 0) ? processedApps.length : VisibleAppCounts;
    setAppCount(processedApps.length);
    setAppList(processedApps.slice(0, visibleCount));
  }, [apps, search, showAll, isSharePointAppSupported, getAvailableApps, appliedFilters]);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleToggleApp = (activeApp: AppClass, checked: boolean) => {
    if (checked) {
      dispatch(updateSelectedApps([...activeApps, createAppDetail(activeApp)]));
    } else {
      dispatch(updateSelectedApps(activeApps.filter((app) => app.appClassId !== activeApp.id)));
    }
    props.handleIsDirty();
  };
  const getActiveApp = (itemId: string) => {
    const isActive = !!activeApps.find((app) => app.appClassId === itemId);
    return isActive;
  };

  const createAppDetail = (app: AppClass) => {
    const appObj = {
      appClassId: app.id,
      appCategoryId: app.appCategoryId,
      appClassName: app.name,
      appPlatformId: app.appPlatformClassId,
      appUrl: app.appRelativeURL,
      isPublished: false,
      key: app.key,
    };
    return appObj;
  };

  const handleRemoveApp = (id: string | number) => {
    if (id) {
      dispatch(updateSelectedApps(activeApps.filter((app) => app.appClassId !== id)));
    }
  }

  const canRemoveApp = (app) => {
    const selectedApp = props.record?.appDetails?.find(item=> item.key === app.key);
    return !(isEditMode && app.key === gridAppPlatformKeys.SHARE_POINT && selectedApp?.isPublished)
  }


  useEffect(() => {
    if (props.record && props.record.id) {
      setShowAll(true);
      setIsEditMode(true);
    }
  }, [props.record]);

  const handleAppliedFilter = (checkedBundle, checkedSSl, isClearAll) => {
    if(isClearAll) {
      setSelectedFilters([]);
      setAppliedFilters({});
      setIsFilterShow(false);
      return;
    }
    const filtredApps = getFilteredApps(checkedBundle, checkedSSl, apps);
    setSelectedFilters([...checkedBundle, ...checkedSSl].map((v)=> ({...v, appClassName: v.name, appClassId: v.id})));
    setAppliedFilters({ checkedBundle, checkedSSl, filtredApps });
    setIsFilterShow(false);
  }
  const handleRemoveFilter = (id: string | number) => {
    const {checkedBundle, checkedSSl} = appliedFilters;
    const updatedBundle = checkedBundle.filter((app) => app.id !== id);
    const updatedSsl = checkedSSl.filter((app) => app.id !== id);
    const filtredApps = getFilteredApps(updatedBundle, updatedSsl, apps);
    setSelectedFilters([...updatedBundle, ...updatedSsl].map((v)=> ({...v, appClassName: v.name, appClassId: v.id})));
    setAppliedFilters({ checkedBundle: updatedBundle, checkedSSl: updatedSsl, filtredApps });
  }

  return (
    <div className="app-selection">
      <div className="title-section">
        <p className="most-popular-apps-label">{showAll || (search && search.length > 0) ? t.admin_portal_app_table_heading : t.create_project_step_1_most_popular_apps}</p>
        <div className="filter-search">
          <span className="filter-icon">
            <AppIconButton aria-label="filter" type="button" size="small" className="app-icon-filter" onClick={() => setIsFilterShow(true)}>
              <AppIcon icon={filter} />
            </AppIconButton>
          </span>
          <span>
            <MotifAppInput
              name={"app Search"}
              maxLength={15}
              placeholder="Search"
              className="search-box motif-search-box"
              value={search}
              onChange={onChangeSearch}
              startAdornment={<AppIcon icon={magnifyingGlass} className="search-box-icon" />}
            />
          </span>
        </div>
      </div>
      <div className="app-col-lg-12 app-col-md-12 divider-line"></div>
      
      {selectedFilters?.length > 0 && (
        <div className="app-col-lg-12 selected-apps-chip">
          <AppChipTypes onRemoveChip={handleRemoveFilter} chipTypeName={t.app_filter_title} chipsData={selectedFilters} />
        </div>
      )}
      {activeApps && activeApps.length > 0 && (
        <div className="app-col-lg-12 selected-apps-chip">
          <AppChipTypes onRemoveChip={handleRemoveApp} chipTypeName={t.confirm_and_create_selected_apps} chipsData={activeApps} canRemoveChip={canRemoveApp} />
        </div>
      )}
      <div className="app-container">
        {appList.length > 0 ? (
          <div className="row">
            {appList.map((item, index) => (
              <div className="col-xs-4 col-lg-3 col-xl-3" key={index}>
                <AppCard
                  key={index}
                  data={{ ...item, isAppLoaded: true }}
                  appCategories={[]}
                  moreMenuEnabled={false}
                  descriptionEnabled={false}
                  footerEnabled={false}
                  showDemoBtn={true}
                  removeEnabled={false}
                  isNewAppCard={true}
                  currentUser={currentUser}
                  showToggleSwitch={true}
                  disableToggleSwitch={!canRemoveApp(item)}
                  isActive={getActiveApp(item.id)}
                  onActiveCard={handleToggleApp}
                />
              </div>
            ))}
          </div>
        ) : (
          !!search.length && (
            <div className="no-apps-found">
              <p className="no-apps-label">{t.app_drawer_no_apps}</p>
            </div>
          )
        )}
      </div>
      {!search.length && appCount > VisibleAppCounts && (
        <div className="show-more-less">
          {!showAll && (
            <AppButton id="load-more-btn" onClick={toggleShowAll} size="medium" className="show-more">
              {t.create_project_step_1_view_all_apps}
              <AppIcon icon={arrowDown} className="load-button-icon" />
            </AppButton>
          )}
        </div>
      )}
      {apps.length === 0 && <Loader isLoading={true} />}
      {isFilterShow && (
        <AppSelectionFilters closeFilter={() => setIsFilterShow(false)} handleAppliedFilter={handleAppliedFilter} allSelectedFilters={appliedFilters} />
      )}
    </div>
  );
};

export default AppSelection;
