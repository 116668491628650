import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { AppFormErrorText, AppIcon, AppModal, MotifAppInput, MotifAppSelect, MotifAppTextArea } from "../../theme";
import AppLabelWithIcon from "../../theme/appLabelWithIcon/appLabelWithIcon";
import { add, xMark } from "../../theme/icons";
import t from "../../localization/en/translation.json";
import Announcement from "./announcement/announcement";
import { createAnnouncementRequest, deleteAnnouncementRequest, flushAnnouncements, getAnnouncementsRequest, updateAnnouncementRequest } from "../../store/actions/project-actions";
import { ANNOUNCEMENT_SCROLL_TARGET, announcementDropdownOptions, isImportantDropDown } from "../../containers/projectDetails/projectDetailConstants";
import "./projectAnnouncements.scss";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";

interface Announcements {
  isImportant: boolean;
  name: string;
  details: string;
  author: string;
  authorId: string;
  modifiedDateTime: string;
  id: string;
}

interface RootState {
  projects: {
    announcements: Announcements[];
    announcementPaginationData: AnnouncementPaginationData;
    deletedAnnouncement: {
      announcementId: string;
    };
    savedAnnouncement: object;
  };
}

interface AnnouncementPaginationData {
  chunk: number;
  isAllAnnouncementsFetched: boolean;
  isLoading: boolean;
}

interface ProjectAnnouncementsProps {
  flyout: boolean;
  closeFlyout: () => void;
  isProjectAdmin: boolean;
  projectId: string;
  isAnnouncementOpen: boolean;
}

const selectAnnouncements = (state: RootState) => state.projects.announcements;
const selectAnnouncementPaginationData = (state: RootState) => state.projects.announcementPaginationData;
const selectDeletedAnnouncement = (state: RootState) => state.projects.deletedAnnouncement;
const selectSavedAnnouncement = (state: RootState) => state.projects.savedAnnouncement;

const ProjectAnnouncements: React.FC<ProjectAnnouncementsProps> = ({ flyout, closeFlyout, isProjectAdmin, projectId, isAnnouncementOpen = false }) => {
  const dispatch = useDispatch();
  const announcements = useSelector(selectAnnouncements);
  const deletedAnnouncement = useSelector(selectDeletedAnnouncement);
  const savedAnnouncement = useSelector(selectSavedAnnouncement);
  const { chunk, isAllAnnouncementsFetched, isLoading = true } = useSelector(selectAnnouncementPaginationData);

  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showDeleteAnnouncementModal, setShowDeleteAnnouncementModal] = useState(false);
  const [announcementDetails, setAnnouncementDetails] = useState("");
  const [announcementName, setAnnouncementName] = useState("");
  const [isImportantAnnouncement, setIsImportantAnnouncement] = useState(isImportantDropDown.NO);
  const [announcementId, setAnnouncementId] = useState("");

  const handleAnnouncementModalClose = () => {
    setShowAnnouncementModal(false);
    const timeoutId = setTimeout(() => {
      setAnnouncementDetails("");
      setAnnouncementName("");
      setIsImportantAnnouncement(isImportantDropDown.NO);
      setAnnouncementId("");
    }, 60);

    return () => clearTimeout(timeoutId);
  };

  const createAnnouncementModel = (showAnnouncementModal: boolean) => {
    setShowAnnouncementModal(showAnnouncementModal);
  };

  const handleAnnouncementSave = () => {
    const isImportant = isImportantAnnouncement === isImportantDropDown.YES;
    const announcementData = { projectId, name: announcementName, details: announcementDetails, isImportant };
    if (announcementId) {
      dispatch(updateAnnouncementRequest({ ...announcementData, id: announcementId }));
    } else {
      dispatch(createAnnouncementRequest(announcementData));
    }
    handleAnnouncementModalClose();
  };

  const handleDeleteAnnouncement = () => {
    if (announcementId) {
      dispatch(deleteAnnouncementRequest({ projectId, announcementId }));
    }
    toggleDeleteAnnouncementModal(false);
  };

  const toggleDeleteAnnouncementModal = (showDeleteAnnouncementModal: boolean, announcementId?: string) => {
    setShowDeleteAnnouncementModal(showDeleteAnnouncementModal);
    if (showDeleteAnnouncementModal && announcementId) {
      setAnnouncementId(announcementId);
    } else {
      setAnnouncementId("");
    }
  };

  const toggleEditAnnouncementModal = (showAnnouncementModal: boolean, announcementId?: string) => {
    const announcement = announcements.find((announcement) => announcement.id === announcementId);
    setShowAnnouncementModal(showAnnouncementModal);
    if (showAnnouncementModal && announcementId && announcement) {
      const isImportantAnnouncement = announcement.isImportant ? isImportantDropDown.YES : isImportantDropDown.NO;
      setAnnouncementId(announcement.id);
      setAnnouncementName(announcement.name);
      setAnnouncementDetails(announcement.details);
      setIsImportantAnnouncement(isImportantAnnouncement);
    } else {
      setAnnouncementId("");
    }
  };

  const getAnnouncementChunk = useCallback(() => {
    dispatch(getAnnouncementsRequest({ projectId, pageNumber: 1 }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (deletedAnnouncement && deletedAnnouncement.announcementId) {
      dispatch(flushAnnouncements());
      getAnnouncementChunk();
    }
  }, [deletedAnnouncement, dispatch, getAnnouncementChunk]);

  useEffect(() => {
    if (savedAnnouncement && Object.keys(savedAnnouncement).length) {
      dispatch(flushAnnouncements());
      getAnnouncementChunk();
    }
  }, [savedAnnouncement, dispatch, getAnnouncementChunk]);

  useEffect(() => {
    if (isAnnouncementOpen) {
      getAnnouncementChunk();
    }
  }, [projectId, isAnnouncementOpen, getAnnouncementChunk]);

  const fetchNextAnnouncementChunk = () => {
    if (!isAllAnnouncementsFetched && isAnnouncementOpen) {
      dispatch(getAnnouncementsRequest({ projectId, pageNumber: chunk }));
    }
  };
  const handleAnnouncementDetailsChange = (e) => {
    setAnnouncementDetails(e.target.value);
  };

  const handleAnnouncementNameChange = (e) => {
    setAnnouncementName(e.target.value);
  };

  const handleIsImportantAnnouncementChange = (e) => {
    setIsImportantAnnouncement(e.target.value);
  };

  return (
    <>
      <Drawer className="project-announcements" anchor="right" open={flyout} onClose={closeFlyout} classes={{ paper: "drawer-paper" }} ModalProps={{ keepMounted: true }}>
        <div className="drawer-content">
          <div className="announcement-header">
            <p className="announcement-label">{t.edit_project_announcement}</p>
            <div className="create-announcement">
              <span className="ann-btn" onClick={() => createAnnouncementModel(true)}>
                <AppLabelWithIcon leftIcon={<AppIcon icon={add} />} labelText={t.announcement_create_btn_text} />
              </span>
              <span className="close-button">
                <AppIcon className="icon" onClick={closeFlyout} icon={xMark} />
              </span>
            </div>
          </div>
          <div className="divider-line"></div>
          <div id={ANNOUNCEMENT_SCROLL_TARGET} className="announcement-list">
            {!isLoading && !announcements.length ? (
              <div className="no-announcement">{t.No_announcement_msg}</div>
            ) : (
              <InfiniteScroll
                dataLength={announcements.length}
                next={fetchNextAnnouncementChunk}
                hasMore={!isAllAnnouncementsFetched}
                loader={<p className="isLoading"> {t.loading}</p>}
                scrollableTarget={ANNOUNCEMENT_SCROLL_TARGET}
              >
                {announcements.map((announcement) => (
                  <Announcement
                    key={`announcement_${announcement.id}`}
                    id={announcement.id}
                    title={announcement.name}
                    description={announcement.details}
                    isImportant={announcement.isImportant}
                    createdBy={announcement.author}
                    createdDate={announcement.modifiedDateTime}
                    isProjectAdmin={isProjectAdmin}
                    authorId={announcement.authorId}
                    deleteAnnouncement={toggleDeleteAnnouncementModal}
                    editAnnouncement={toggleEditAnnouncementModal}
                  />
                ))}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </Drawer>
      <div className="delete-announcement-modal">
        <AppModal
          size="sm"
          showModal={showDeleteAnnouncementModal}
          cancelBtnText={t.cancel}
          confirmBtnText={t.delete_draft_modal_delete_btn}
          onConfirm={() => handleDeleteAnnouncement()}
          onModalClose={() => toggleDeleteAnnouncementModal(false)}
          showCloseModelIcon={false}
          showDividers={false}
          modalClass={"delete-ann-model"}
        >
          <div className="delete-model-content">
            <p>{t.delete_announcement_modal_msg}</p>
            <span className="delete-close-model">
              <AppIcon className="icon" onClick={() => toggleDeleteAnnouncementModal(false)} icon={xMark} />
            </span>
          </div>
        </AppModal>
      </div>
      <div className="announcement-modal">
        <AppModal
          newDesign={true}
          size="md"
          modalClass="create-update-ann-modal"
          title={announcementId ? t.announcement_modal_edit_title : t.announcement_modal_title}
          showModal={showAnnouncementModal}
          cancelBtnText={t.cancel}
          confirmBtnText={announcementId ? t.update : t.announcement_save_btn_text}
          onConfirm={handleAnnouncementSave}
          onModalClose={handleAnnouncementModalClose}
          disabledValue={!announcementName.trim().length || validateFormInputForSpecialChar(announcementName) || validateFormInputForSpecialChar(announcementDetails)}
        >
          <div className="announcement-form">
            <div className="input-row">
              <div className="input-field">
                <span className="label">{t.announcement_name_label}</span>
                <MotifAppInput
                  value={announcementName}
                  maxLength={50}
                  isValid={!validateFormInputForSpecialChar(announcementName)}
                  onChange={handleAnnouncementNameChange}
                  placeholder={t.enterValue}
                />
                {!validateFormInputForSpecialChar(announcementName) || (
                  <AppFormErrorText>{t.all_ssp_validate_special_character_in_text_error.replace("{1}", announcementName.trim().split("")[0])}</AppFormErrorText>
                )}
              </div>
              <div className="input-field">
                <span className="label">{t.announcement_important_label}</span>
                <MotifAppSelect value={isImportantAnnouncement} options={announcementDropdownOptions} onChange={handleIsImportantAnnouncementChange} placeholder="Select" />
              </div>
            </div>
            <div className="input-row">
              <div className="text-area-field">
                <span className="label">{t.announcement_details_label}</span>
                <MotifAppTextArea
                  maxLength={250}
                  value={announcementDetails}
                  isValid={!validateFormInputForSpecialChar(announcementDetails)}
                  onChange={handleAnnouncementDetailsChange}
                  placeholder={t.enterDescription}
                />
                {!validateFormInputForSpecialChar(announcementDetails) || (
                  <AppFormErrorText>{t.all_ssp_validate_special_character_in_text_error.replace("{1}", announcementDetails.trim().split("")[0])}</AppFormErrorText>
                )}
              </div>
            </div>
          </div>
        </AppModal>
      </div>
    </>
  );
};

export default ProjectAnnouncements;
