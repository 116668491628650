import t from "../../localization/en/translation.json";
import "./projectHomeDetails.scss";
import { AppButton, AppIcon, AppTooltip } from "../../theme";
import { circleInfo, editPen } from "../../theme/icons";
import { formatDate, isPlatformError, isPlatformPending, isPlatformReady, isPorjectErrored } from "../../utils/helper-utility";
import { ProjectStatus } from "../projectCard/projectCardConstants";
import { SessionKeys } from "../../utils/Constants";
import { useHistory } from "react-router";
import { PROJECT_USAGE_REPORT } from "../../containers/projectDetails/projectDetailConstants";
import { Link } from "react-router-dom";
import speaker from "../../assets/images/icons/speaker.svg";

export const ProjectHomeDetails = ({
  projectDetails,
  projectStatuses,
  isProjectAdmin = false,
  showNoOfUserCount = false,
  isProjectActive,
  isProjectNonActive = false,
  isLegacyProject = false,
  isManageAssistantEnabled = false,
  toggleAnnouncementFlyout,
}) => {
  const history = useHistory();
  const projectAdmins = projectDetails?.projectAdmins || [];
  const projectReport = projectDetails?.projectReports?.find((report) => report.key === PROJECT_USAGE_REPORT);
  const isPending = isPlatformPending(projectStatuses, projectDetails?.appPlatformDetails, projectDetails?.infrastructureStatusId);
  const isError = isPlatformError(projectStatuses, projectDetails?.appPlatformDetails, projectDetails?.infrastructureStatusId);
  const isReady = isPlatformReady(projectStatuses, projectDetails?.appPlatformDetails, projectDetails?.infrastructureStatusId);
  const isProjectInError = isPorjectErrored(projectStatuses, projectDetails?.projectStatus.id);
  const isDraft = projectDetails?.projectStatus?.projectStatusName.toLowerCase() === ProjectStatus.DRAFT;
  const isUsageReportBtn = isProjectAdmin && !isDraft && projectReport;

  const getManageWorkflowsBtnTooltipMsg = (isDraft: boolean, isPending: boolean, isPlatformError: boolean, isProjectInError: boolean, isLegacyProject: boolean) => {
    switch (true) {
      case isDraft:
        return t.project_manage_workflows_draft_tooltip;
      case isProjectInError:
        return t.project_manage_workflows_tooltip_error;
      case isPending:
        return t.project_manage_workflows_tooltip_platform_inprogress;
      case isPlatformError:
        return t.project_manage_workflows_platform_error;
      case isLegacyProject:
        return t.project_manage_workflows_legacy_project;
      default:
        return "";
    }
  };
  const manageWorkflowsTooltipMsg = getManageWorkflowsBtnTooltipMsg(isDraft, isPending, isError, isProjectInError, isLegacyProject);

  const gotoManageUsers = () => {
    sessionStorage.setItem(SessionKeys.HOME_PAGE_PROJECT_TILE_CLICK, projectDetails?.projectFriendlyId + "");
    if (!isDraft) {
      history.push(`/project/${projectDetails?.id}/manage-users`);
    }
  };

  const gotoUsageReports = () => {
    if (isUsageReportBtn) {
      history.push(`/project/${projectDetails?.id}/usage-reports/${projectReport?.pbiReportId}`);
    }
  };
  const gotoManageCeAssistant = () =>{
    if (!isDraft) {
      history.push(`/project/${projectDetails?.id}/manage-assistant`);
    }
  }
  const getEditBtnTooltipMsg = (isPending: boolean, isError: boolean, isProjectInError: boolean) => {
    if (isProjectInError) {
      return t.edit_project_tooltip_message_error;
    } else if (isPending) {
      return t.edit_project_tooltip_message;
    } else if (isError) {
      return t.edit_project_platform_error;
    } else {
      return "";
    }
  };

  const editBtnTooltipMsg = getEditBtnTooltipMsg(isPending, isError, isProjectInError);

  const renderProjectAdmins = () => {
    const displayedAdmins = projectAdmins.slice(0, 2);
    const remainingAdmins = projectAdmins.length - displayedAdmins.length;
    return (
      <>
        {displayedAdmins.map((admin, index) => (
          <span key={admin}>
            {admin}
            {index < displayedAdmins.length - 1 ? ", " : " "}
          </span>
        ))}
        {remainingAdmins > 0 && (
          <AppTooltip title={projectAdmins.slice(2).join(", ")}>
            <span className="remaining-count">+{remainingAdmins}</span>
          </AppTooltip>
        )}
      </>
    );
  };

  return (
    <div className="container project-home">
      <div className="row">
        <div className="app-col-lg-4 app-col-md-4 app-col-xs-4 pl-0">
          <span className="project-home-info">
            <h4 className="project-home-name">{projectDetails?.name || "NA"}</h4>
            {projectDetails?.description && (
              <span className="project-home-info-icon">
                <AppTooltip title={projectDetails.description}>
                  <AppIcon icon={circleInfo} className="icon-link" />
                </AppTooltip>
              </span>
            )}
            {isDraft ? <span className="draft-icon">{t.draft} </span> : null}
          </span>
        </div>
        <div className="app-col-lg-8 app-col-md-8 app-col-xs-8 pl-0">
          <div className="action-btn">
            <div className="default-action-button">
              {showNoOfUserCount && (
                <AppTooltip hide={!isDraft} title={isDraft ? t.project_setting_tooltip_message : ""} className={isDraft ? "disabled-btn-default" : ""}>
                  <AppButton variant="outlined" color="default" onClick={() => gotoManageUsers()} disabled={isDraft}>
                    {t.step_three_title}
                  </AppButton>
                </AppTooltip>
              )}
              {isUsageReportBtn && (
                <AppButton variant="outlined" color="default" onClick={() => gotoUsageReports()} disabled={!isUsageReportBtn} className={!isUsageReportBtn ? "disabled-btn-default" : ""}>
                  {t.project_home_view_usage_report}
                </AppButton>
              )}
              {projectStatuses.length && isProjectAdmin && (
                <AppTooltip hide={!isDraft && !isPending && !isError && !isProjectInError && !isLegacyProject} title={manageWorkflowsTooltipMsg} className={isProjectNonActive ? "disabled-btn-default" : ""}>
                  <Link to={isProjectNonActive ? "#" : `/project/${projectDetails?.id}/manage-workflows/workflows/definitions`}>
                    <AppButton variant="outlined" color="default" disabled={isProjectNonActive}>
                      {t.project_details_meta_workflows}
                    </AppButton>
                  </Link>
                </AppTooltip>
              )}
              {isManageAssistantEnabled && isProjectAdmin && (
                <AppButton
                  disabled={isProjectNonActive}
                  variant="outlined"
                  color="default"
                  onClick={() => !isProjectNonActive && gotoManageCeAssistant()}
                  className={isProjectNonActive ? "disabled-btn-default" : ""}
                >
                  {t.manage_assistant}
                </AppButton>
              )}
            </div>

            {projectStatuses.length && isProjectAdmin && (
              <AppTooltip placement="bottom" hide={!isPending && !isProjectInError && !isError} title={editBtnTooltipMsg} className={isPending || isError || isProjectInError ? "disabled-btn" : ""}>
                <Link to={isPending || isError || isProjectInError ? "#" : `/project/${projectDetails?.id}/project-details`}>
                  <AppButton size="medium" disabled={isPending || isError || isProjectInError} className="project-home-edit-btn">
                    <AppIcon icon={editPen} className="icon-link" />
                    {t.project_settings}
                  </AppButton>
                </Link>
              </AppTooltip>
            )}
            {(isProjectActive || isReady) && (
              <div
                className="announcement-btn"
                onClick={() => {
                  toggleAnnouncementFlyout(true);
                }}
              >
                <img src={speaker} alt="speaker logo" width={36} height={36} />
              </div>
            )}
          </div>
        </div>
        <div className="app-col-lg-12 app-col-md-12 project-home-divider-line"></div>
        <div className="app-col-lg-6 app-col-md-6 app-col-xs-4 pl-0">
          <div className="project-home-label-space">
            <span className="project-home-label">{t.project_home_created_by}</span>
            <span className="project-home-value">{projectDetails?.createdByUserName || "NA"}</span>
          </div>
          <div>
            <span className="project-home-label">{t.project_home_admin}</span>
            <span className="project-home-value">{renderProjectAdmins()}</span>
          </div>
        </div>
        <div className="app-col-lg-6 app-col-md-6 app-col-xs-4 project-home-text-right">
          <div className="project-home-label-space">
            <span className="project-home-label">{t.project_home_created_on}</span>
            <span className="project-home-value">{formatDate(projectDetails?.createdOn)}</span>
          </div>
          <div>
            <span className="project-home-label">{t.project_home_client_name}</span>
            <span className="project-home-value ">
              {projectDetails?.clientName || "NA"}
              {showNoOfUserCount && !isDraft && projectDetails?.numberOfUsers ? (
                <span className="project-home-total-user">{`${projectDetails.numberOfUsers} ${projectDetails.numberOfUsers === 1 ? t.project_home_user : t.project_home_users}`}</span>
              ) : null}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
