import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import List from "../../editableGrid/list/list";
import {
  getWorkflowsAction,
  clearWorkflowsAction,
  searchWorkflowsAction,
  clearSearchWorkflowsAction,
  deleteWorkflowsAction,
  updateWorkflowsAction,
  addWorkflowsAction,
  flushDeleteWorkflowsData,
  getCommTemplateCategoryAction,
  clearCommTemplateCategoryAction,
  getWorkflowDefinitionsAction,
  clearWorkflowDefinitionsAction,
} from "../../../store/actions/admin-workflows-actions";
import { updatefilterState } from "../../../store/actions/list-actions";
import {
  DEFAULT_SORT,
  ROW_MIN_HEIGHT,
  MORE_MENU_ACTION_NAME,
  FORM_COMMUNICATION_TEMPLATES,
} from "./adminCommunicationTemplatesConstants";
import {
  getDefaultPaginationModel,
  getPaginationModel,
} from "../../../models/IPagination";
import t from "../../../localization/en/translation.json";
import { AppIcon, AppModal } from "../../../theme";
import { refresh, trash, add, search } from "../../../theme/icons";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import { columnConfig } from "./columnConfig";
import { MoreMenuOption } from "../../moreMenu/moreMenuOptions";
import MoreMenu from "../../moreMenu/moreMenu";
import AppNotification from "../../appNotification/appNotification";
import { EWorkFlowTableName } from "../../../utils/Constants";
import "./adminCommunicationTemplates.scss";
import { CommunicationFields, WFFields } from "../adminWorkflowConstant";
import GenericFormModal from "../../genericFormModal/genericFormModal";
import * as _ from "lodash";
import { adminCommunicationTemplatesProps, IState } from "./adminCommunicationTemplatesModel";
import { getStringWithBold } from "../../../utils/helper-utility";
import AppLabelWithIcon from "../../../theme/appLabelWithIcon/appLabelWithIcon";

type IProps = RouteComponentProps<any> & adminCommunicationTemplatesProps;

class AdminCommunicationTemplates extends Component<IProps, IState> {
  gridApi;
  typeahead;
  formFields = this.setFormWorkflow();
  state = {
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: [],
    }),
    searchQuery: [],
    showDeleteModal: false,
    selectedRow: [],
    showNotification: false,
    notificationVariant: "",
    notificationMessage: "",
    showAddOrEditModal: false,
    isEdit: false,
    formFields: this.formFields,
  };

  componentDidMount() {
    this.getWorkflows();
    this.updateFilterState();
    this.getCommTemplateCategory();
    this.getWorkflowDefinitions();
  }

  componentDidUpdate(prevProps, prevState) {
    const { paginationPayload } = this.state;
    if (prevState.paginationPayload !== paginationPayload) {
      this.getWorkflows();
      this.updateFilterState();
    }

    this.manageNotification(prevProps);
  }

  componentWillUnmount() {
    this.props.clearWorkflowsAction();
    this.props.clearSearchWorkflowsAction();
    this.props.clearCommTemplateCategoryAction();
    this.props.clearWorkflowDefinitionsAction();
  }

  checkProjectAccess = () => {
    const { userAdminAccess, isAdminProjectPage, isProjectAdmin, match: { params: { id } } } = this.props;
    if (id) {
      return isProjectAdmin;
    }
    return isAdminProjectPage ? (userAdminAccess.portalAdmin || userAdminAccess.portalAdminConsole) : userAdminAccess.portalAdmin;
  }

  getWorkflows = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { paginationPayload } = this.state;
    paginationPayload.id = id;
    paginationPayload.tableName = EWorkFlowTableName.COMMUNICATION_TEMPLATES;
    paginationPayload.apiURL = paginationPayload.tableName;
    if (id) {
      paginationPayload.apiURL = `${id}/${paginationPayload.apiURL}`;
    }
    this.props.getWorkflowsAction(paginationPayload);
  };

  getWorkflowDefinitions = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    let apiURL = EWorkFlowTableName.WORKFLOW_DEFINITIONS as string;
    if (id) {
      apiURL = `${id}/${apiURL}`;
    }

    const pagination = {
      id,
      apiURL,
      pageSize: -1,
    };
    this.props.getWorkflowDefinitionsAction(pagination);
  };

  getCommTemplateCategory = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    let apiURL = EWorkFlowTableName.COMMUNICATION_TEMPLATE_CATEGORY as string;
    if (id) {
      apiURL = `${id}/${apiURL}`;
    }
    const pagination = {
      id,
      apiURL,
      pageSize: -1,
    };
    this.props.getCommTemplateCategoryAction(pagination);
  };

  handleGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  };

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  };

  handleSorting = (
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;

    if (
      paginationPayload.sortColumn === sortColumn &&
      paginationPayload.sortDirection === sortDirection
    ) {
      return;
    }

    const updatedPaginationPayload = {
      ...paginationPayload,
      sortColumn,
      sortDirection,
    };
    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleFilterChange = (
    filters,
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;
    const updatedPayload = {
      ...paginationPayload,
      filters,
      sortColumn,
      sortDirection,
      pageNumber: 1,
    };

    this.setState({ paginationPayload: updatedPayload });
  };

  updateFilterState = () => {
    const {
      filters = [],
      sortDirection,
      sortColumn,
    } = this.state.paginationPayload;
    const isDefaultSort =
      sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length;

    this.props.updatefilterState({
      filters,
      sortDirection,
      sortColumn,
      isClearAllDisabled,
    });
  };

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = {
      ...paginationPayload,
      ...paginationParams,
    };

    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleSearchData = (query) => {
    const { paginationPayload } = this.state;
    if (
      paginationPayload &&
      paginationPayload.tableName
    ) {
      let searchUrl = paginationPayload.tableName;
      if (paginationPayload.id) {
        searchUrl = `${paginationPayload.id}/${searchUrl}`;
      }
      this.props.fetchSearchData({ query, searchUrl });
    }
  };

  handleSelectValue = (selectedArray) => {
    this.setState({
      ...this.state,
      searchQuery: selectedArray,
    });
  };

  clearSearch = () => {
    if (this.typeahead) {
      this.typeahead.clear();
    }
  };

  renderMenuItem = (option, props) => {
    const stringData = option[WFFields.title] + ", " + option[CommunicationFields.communicationTemplateDescription];
    return (
      <div className="async-dropdown" dangerouslySetInnerHTML={{ __html: getStringWithBold(stringData, props.text) }}>
      </div>
    );
  };

  handleMoreMenuOptionClick = (actionName: string) => {
    if (actionName === MORE_MENU_ACTION_NAME.UPDATE) {
      const rows = this.gridApi.getSelectedRows();
      const formFields = this.setFormWorkflow(rows[0]);
      this.setState({
        showAddOrEditModal: true,
        isEdit: true,
        formFields,
      });
    } else if (actionName === MORE_MENU_ACTION_NAME.DELETE) {
      const rows = this.gridApi.getSelectedRows();
      this.setState({ showDeleteModal: true, selectedRow: rows[0] });
    }
  };

  renderMoreMenu = () => {
    const checkProjectAccessRole = this.checkProjectAccess();
    const options: MoreMenuOption[] = [
      {
        id: "123",
        title: t.update,
        icon: refresh,
        actionName: MORE_MENU_ACTION_NAME.UPDATE,
        isDisabled: !checkProjectAccessRole,
      },
      {
        id: "1234",
        title: t.delete,
        icon: trash,
        actionName: MORE_MENU_ACTION_NAME.DELETE,
        isDisabled: !checkProjectAccessRole,
      },
    ];

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={this.handleMoreMenuOptionClick}
        options={options}
        vertical
        leftPlacement
      />
    );
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  deleteWorkflows = () => {
    const { selectedRow, paginationPayload } = this.state;
    if (
      selectedRow &&
      selectedRow[WFFields.id] &&
      paginationPayload &&
      paginationPayload.tableName
    ) {
      let deleteUrl = `${paginationPayload.tableName}/${selectedRow[WFFields.id]}`;
      if (paginationPayload.id) {
        deleteUrl = `${paginationPayload.id}/${deleteUrl}`;
      }
      this.props.deleteWorkflowsAction(deleteUrl);
    }
    this.setState({ showDeleteModal: false });
  };

  showNotification = (action: string, success: boolean) => {
    let notificationVariant = "";
    let notificationMessage = "";
    if (action === MORE_MENU_ACTION_NAME.ADD) {
      notificationVariant = success ? "success" : "error";
      notificationMessage = success
        ? t.admin_cps_add_success
        : t.admin_cps_add_error;
    } else if (action === MORE_MENU_ACTION_NAME.UPDATE) {
      notificationVariant = success ? "success" : "error";
      notificationMessage = success
        ? t.admin_cps_edit_success
        : t.admin_cps_edit_error;
    } else if (action === MORE_MENU_ACTION_NAME.DELETE) {
      notificationVariant = success ? "success" : "error";
      notificationMessage = success
        ? t.admin_cps_delete_success
        : t.admin_cps_delete_error;
    }

    this.setState({
      showNotification: true,
      notificationVariant,
      notificationMessage,
    });
    setTimeout(() => {
      if (this.state.showNotification) {
        this.setState({
          showNotification: false,
          notificationVariant,
          notificationMessage,
        });
      }
    }, 3000);
  };

  manageNotification = (prevProps) => {
    const { searchQuery } = this.state;
    const {
      addAdminWorkflowsData,
      updateAdminWorkflowsData,
      deleteAdminWorkflowsData,
    } = this.props;

    if (
      addAdminWorkflowsData !== prevProps.addAdminWorkflowsData &&
      addAdminWorkflowsData?.success === true
    ) {
      this.showNotification(MORE_MENU_ACTION_NAME.ADD, true);
      this.getWorkflows();
    } else if (
      addAdminWorkflowsData !== prevProps.addAdminWorkflowsData &&
      addAdminWorkflowsData?.success === false
    ) {
      this.showNotification(MORE_MENU_ACTION_NAME.ADD, false);
    }

    if (
      updateAdminWorkflowsData !== prevProps.updateAdminWorkflowsData &&
      updateAdminWorkflowsData?.success === true
    ) {
      this.showNotification(MORE_MENU_ACTION_NAME.UPDATE, true);
      this.getWorkflows();
      if (searchQuery.length) {
        this.clearSearch();
        this.setState({ searchQuery: [] });
      }
    } else if (
      updateAdminWorkflowsData !== prevProps.updateAdminWorkflowsData &&
      updateAdminWorkflowsData?.success === false
    ) {
      this.showNotification(MORE_MENU_ACTION_NAME.UPDATE, false);
    }
    if (deleteAdminWorkflowsData !== prevProps.deleteAdminWorkflowsData) {
      if (deleteAdminWorkflowsData?.success) {
        this.showNotification(MORE_MENU_ACTION_NAME.DELETE, true);
        this.props.flushDeleteWorkflowsData();
        this.getWorkflows();
        if (searchQuery.length) {
          this.clearSearch();
          this.setState({ searchQuery: [] });
        }
      } else if (deleteAdminWorkflowsData?.error) {
        this.showNotification(MORE_MENU_ACTION_NAME.DELETE, false);
      }
    }
  };

  closeNotification = () => {
    this.setState({
      showNotification: false,
      notificationMessage: "",
      notificationVariant: "",
    });
  };

  handleAddRow = () => {
    if (this.checkProjectAccess()) {
      const formFields = this.setFormWorkflow();
      this.setState({
        showAddOrEditModal: true,
        isEdit: false,
        formFields,
      });
    }
  };

  setFormWorkflow(selectedWorkflow?) {
    const { adminCommTemplateCategoryData, adminWorkflowDefinitionsData } =
      this.props;
    const formField = _.cloneDeep(FORM_COMMUNICATION_TEMPLATES);

    _.forEach(formField, (value) => {
      if (
        adminCommTemplateCategoryData &&
        adminCommTemplateCategoryData.length &&
        value.fieldName === CommunicationFields.communicationTemplateCategoryId
      ) {
        value.fieldOptions = adminCommTemplateCategoryData;
      }
      if (
        adminWorkflowDefinitionsData &&
        adminWorkflowDefinitionsData.length &&
        value.fieldName === CommunicationFields.wfr_WorkflowLookupId
      ) {
        value.fieldOptions = adminWorkflowDefinitionsData;
      }
      if (selectedWorkflow && _.has(selectedWorkflow, value.fieldName)) {
        value.fieldVal = selectedWorkflow[value.fieldName];
        if (value.isRequired) {
          value.isTouched = !_.isNil(value.fieldVal) && value.fieldVal.toString().trim().length > 0;
        } else {
          value.isTouched = true;
        }
        if (value.fieldName === WFFields.id) {
          value.isHidden = false;
        }
      }
    });
    return formField;
  }

  handleAddOrEditWorkflow = () => {
    const {
      formFields,
      isEdit,
      paginationPayload: { id, tableName },
    } = this.state;
    const formData = _.reduce(
      formFields,
      (formObj, v) => {
        formObj[v.fieldName] = v.fieldVal;
        return formObj;
      },
      {}
    );
    if (formData && tableName) {
      if (!isEdit) {
        delete formData.id;
        this.props.addWorkflowsAction({ formData, projectId: id, tableName });
      } else if (formData.id) {
        this.props.updateWorkflowsAction({
          formData,
          projectId: id,
          tableName,
        });
      }
    }
    this.closeAddOrEditWorkflowModal();
  };

  closeAddOrEditWorkflowModal = () => {
    const formFields = this.setFormWorkflow();
    this.setState({ ...this.state, showAddOrEditModal: false, formFields });
  };

  renderNotification = () => {
    const { showNotification, notificationMessage, notificationVariant } =
      this.state;
    return (
      <>
        {showNotification && (
          <AppNotification
            message={notificationMessage}
            variant={notificationVariant}
            onCloseNotifcation={this.closeNotification}
          />
        )}
      </>
    );
  };

  render() {
    const {
      adminWorkflowsData,
      paginationDetails,
      searchLoader,
      searchData
    } = this.props;
    const {
      paginationPayload,
      searchQuery,
      showDeleteModal,
      selectedRow,
      showAddOrEditModal,
      isEdit,
      formFields,
    } = this.state;
    const rowData = searchQuery.length ? searchQuery : adminWorkflowsData;
    const currentPage = searchQuery.length ? 1 : paginationPayload.pageNumber;
    const checkProjectAccessRole = this.checkProjectAccess();
    if (paginationDetails) {
      paginationPayload.totalRecords = paginationDetails.totalRecords;
      paginationPayload.totalRecordsWithoutFilter =
        paginationDetails.totalRecords ||
        paginationPayload.totalRecordsWithoutFilter;
    }
    if (searchQuery.length) {
      paginationPayload.totalRecords = searchQuery.length;
    }

    return (
      <div className={`admin-templates`}>
        <div className="search-wrapper">
          <div className="add-delete-wrapper">
            <AppLabelWithIcon
              leftIcon={<AppIcon icon={add} />}
              labelText={`${t.manage_workflows_add_templates}`}
              handleOnClick={() => this.handleAddRow()}
              isDisabled={!checkProjectAccessRole}
            />
          </div>
          <div className="project-form-field">
            <AppIcon icon={search} />
            <AsyncTypeahead
              id="async-search"
              isLoading={searchLoader}
              filterBy={[WFFields.title]}
              labelKey={(option) => `${option[WFFields.title]}`}
              minLength={3}
              onSearch={this.handleSearchData}
              options={searchData}
              onChange={(selected) => this.handleSelectValue(selected)}
              placeholder={t.admin_cps_project_search}
              ref={(ref) => (this.typeahead = ref)}
              useCache={false}
              renderMenuItemChildren={(option, props) =>
                this.renderMenuItem(option, props)
              }
            >
              {({ onClear, selected }) => (
                <div className="rbt-aux">
                  {!!selected.length && <ClearButton onClick={onClear} />}
                </div>
              )}
            </AsyncTypeahead>
          </div>
        </div>
        <div className={`admin-templates-table`}>
          <List
            columnConfig={columnConfig}
            defaultColDef={defaultColDef}
            firstColumnBorderRight={true}
            rowData={rowData}
            paginate={true}
            withStickyScroll
            frameworkComponents={{
              moreMenuCellRenderer: this.renderMoreMenu,
            }}
            handleGridReady={this.handleGridReady}
            onSortChanged={this.handleSorting}
            currentPage={currentPage}
            pagination={paginationPayload}
            setItemsPerPage={this.handlePageSizeChange}
            onPageChange={this.handlePageNumberChange}
            onFilterChange={this.handleFilterChange}
            defaultSort={[DEFAULT_SORT]}
            getRowHeight={() => ROW_MIN_HEIGHT}
          />
        </div>
        <AppModal
          showModal={showDeleteModal}
          onModalClose={this.closeDeleteModal}
          onConfirm={this.deleteWorkflows}
          cancelBtnText={t.cancel}
          confirmBtnText={t.delete}
          title={t.admin_cps_modal_delete_title}
        >
          <div className="modal-content deleteModal">
            <div className="app-title-bold">
              <span className="app-modal-delete-body">
                {t.admin_apps_modal_delete_body}{" "}
              </span>
              <span className="red-highlighted">{t.delete} </span>
              <span>{selectedRow && selectedRow[WFFields.title]}? </span>
            </div>
          </div>
        </AppModal>
        {showAddOrEditModal && (
          <GenericFormModal
            title={t.admin_cps_form_title_template}
            isOpen={showAddOrEditModal}
            isEdit={isEdit}
            handleConfirm={this.handleAddOrEditWorkflow}
            onModalClose={this.closeAddOrEditWorkflowModal}
            formFields={formFields}
          ></GenericFormModal>
        )}
        {this.renderNotification()}
      </div>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};
const mapStateToProps = ({ workflowsReducer }) => {
  return {
    adminWorkflowsData: workflowsReducer.adminWorkflowsData,
    paginationDetails: workflowsReducer.paginationDetails,
    searchLoader: workflowsReducer.searchLoader,
    searchData: workflowsReducer.searchData,
    deleteAdminWorkflowsData: workflowsReducer.deleteAdminWorkflowsData,
    updateAdminWorkflowsData: workflowsReducer.updateAdminWorkflowsData,
    addAdminWorkflowsData: workflowsReducer.addAdminWorkflowsData,
    adminCommTemplateCategoryData:
      workflowsReducer.adminCommTemplateCategoryData,
    adminWorkflowDefinitionsData: workflowsReducer.adminWorkflowDefinitionsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkflowsAction: (payload) => {
      dispatch(getWorkflowsAction(payload));
    },
    updatefilterState: (payload) => {
      dispatch(updatefilterState(payload));
    },
    clearWorkflowsAction: () => {
      dispatch(clearWorkflowsAction());
    },
    fetchSearchData: (payload) => {
      dispatch(searchWorkflowsAction(payload));
    },
    clearSearchWorkflowsAction: () => {
      dispatch(clearSearchWorkflowsAction());
    },
    deleteWorkflowsAction: (payload) => {
      dispatch(deleteWorkflowsAction(payload));
    },
    updateWorkflowsAction: (payload) => {
      dispatch(updateWorkflowsAction(payload));
    },
    addWorkflowsAction: (payload) => {
      dispatch(addWorkflowsAction(payload));
    },
    flushDeleteWorkflowsData: () => {
      dispatch(flushDeleteWorkflowsData());
    },
    getCommTemplateCategoryAction: (payload) => {
      dispatch(getCommTemplateCategoryAction(payload));
    },
    clearCommTemplateCategoryAction: () => {
      dispatch(clearCommTemplateCategoryAction());
    },
    getWorkflowDefinitionsAction: (payload) => {
      dispatch(getWorkflowDefinitionsAction(payload));
    },
    clearWorkflowDefinitionsAction: () => {
      dispatch(clearWorkflowDefinitionsAction());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminCommunicationTemplates)
);
