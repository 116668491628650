import * as type from "../action-types";

type load = any;

export const getProjectDetails = (payload: load) => ({
  type: type.GET_PROJECT_DETAILS_REQUEST,
  payload,
});

export const getProjectDetailsSuccess = (payload: load) => ({
  type: type.GET_PROJECT_DETAILS_SUCCESS,
  payload,
});

export const getProjectDetailsFail = (payload: load) => ({
  type: type.GET_PROJECT_DETAILS_FAILURE,
  payload,
});

export const getProjectMetaData = (payload: load) => ({
  type: type.GET_PROJECT_METADATA_REQUEST,
  payload,
});

export const getProjectMetaDataSuccess = (payload: load) => ({
  type: type.GET_PROJECT_METADATA_SUCCESS,
  payload,
});

export const getProjectMetaDataFail = (payload: load) => ({
  type: type.GET_PROJECT_DETAILS_FAILURE,
  payload,
});

export const flushProjectDetails = () => ({
  type: type.FLUSH_PROJECT_DETAILS,
});

export const updateProjectAppPublish = (payload: load) => ({
  type: type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_REQUEST,
  payload,
});

export const updateProjectAppPublishSuccess = (payload?: load) => ({
  type: type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_SUCCESS,
  payload,
});

export const updateProjectAppPublishFail = (payload: load) => ({
  type: type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_FAILURE,
  payload,
});

export const getProjectList = () => ({
  type: type.GET_PROJECT_LIST_REQUEST,
});

export const getProjectsSummary = () => ({
  type: type.GET_PROJECTS_SUMMARY_REQUEST
});

export const getProjectListSuccess = (payload: load) => ({
  type: type.GET_PROJECT_LIST_SUCCESS,
  payload,
});

export const getProjectListFail = (payload: load) => ({
  type: type.GET_PROJECT_LIST_FAILURE,
  payload,
});

export const getProjectUsersList = (payload: load) => ({
  type: type.GET_PROJECT_USERS_LIST_REQUEST,
  payload,
});

export const getProjectUsersListSuccess = (payload: load) => ({
  type: type.GET_PROJECT_USERS_LIST_SUCCESS,
  payload,
});

export const getProjectUsersListFail = (payload: load) => ({
  type: type.GET_PROJECT_USERS_LIST_FAILURE,
  payload,
});

export const getProjectUsersAccessList = (payload: load) => ({
  type: type.GET_PROJECT_USERS_ACCESS_LIST_REQUEST,
  payload,
});

export const getProjectUsersAccessListSuccess = (payload: load) => ({
  type: type.GET_PROJECT_USERS_ACCESS_LIST_SUCCESS,
  payload,
});

export const getProjectUsersAccessListFail = (payload: load) => ({
  type: type.GET_PROJECT_USERS_ACCESS_LIST_FAILURE,
  payload,
});

export const updateManageUser = (payload: any) => ({
  type: type.UPDATE_MANAGE_USER_REQUEST,
  payload,
});

export const updateManageUserSuccess = (payload?: any) => ({
  type: type.UPDATE_MANAGE_USER_SUCCESS,
  payload,
});

export const updateManageUserFailure = (payload: any) => ({
  type: type.UPDATE_MANAGE_USER_FAILURE,
  payload,
});

export const updateManageUserReset = () => ({
  type: type.UPDATE_MANAGE_USER_RESET,
});

export const createAnnouncementRequest = (payload: load) => ({
  type: type.CREATE_ANNOUNCEMENT_REQUEST,
  payload,
});

export const createAnnouncementSuccess = (payload: load) => ({
  type: type.CREATE_ANNOUNCEMENT_SUCCESS,
  payload,
});

export const createAnnouncementFailure = (payload: load) => ({
  type: type.CREATE_ANNOUNCEMENT_FAILURE,
  payload,
});

export const flushAnnouncementNotification = () => ({
  type: type.FLUSH_ANNOUCEMENT_NOTIFICATION,
});

export const getAnnouncementsRequest = (payload: load) => ({
  type: type.GET_ANNOUNCEMENTS_CHUNK,
  payload,
});

export const getAnnouncementsSuccess = (payload: load) => ({
  type: type.GET_ANNOUNCEMENTS_SUCCESS,
  payload,
});

export const getAnnouncementsFailure = (payload: load) => ({
  type: type.GET_ANNOUNCEMENTS_FAILURE,
  payload,
});

export const flushAnnouncements = () => ({
  type: type.FLUSH_ANNOUNCEMENTS,
});

export const updateAnnouncementRequest = (payload: load) => ({
  type: type.UPDATE_ANNOUNCEMENT_REQUEST,
  payload,
});

export const updateAnnouncementSuccess = (payload: load) => ({
  type: type.UPDATE_ANNOUNCEMENT_SUCCESS,
  payload,
});

export const updateAnnouncementFailure = (payload: load) => ({
  type: type.UPDATE_ANNOUNCEMENT_FAILURE,
  payload,
});

export const getMyProjectRightRequest = (payload: load) => ({
  type: type.GET_MY_PROJECT_RIGHT_REQUEST,
  payload,
});

export const getMyProjectRightSuccess = (payload: load) => ({
  type: type.GET_MY_PROJECT_RIGHT_SUCCESS,
  payload,
});

export const getMyProjectRightFailure = (payload: load) => ({
  type: type.GET_MY_PROJECT_RIGHT_FAILURE,
  payload,
});

export const deleteAnnouncementRequest = (payload: load) => ({
  type: type.DELETE_ANNOUNCEMENT_REQUEST,
  payload,
});

export const deleteAnnouncementSuccess = (payload: load) => ({
  type: type.DELETE_ANNOUNCEMENT_SUCCESS,
  payload,
});

export const deleteAnnouncementFailure = (payload: load) => ({
  type: type.DELETE_ANNOUNCEMENT_FAILURE,
  payload,
});

export const getWrikeSyncSettingsRequest = (payload: load) => ({
  type: type.GET_WRIKE_SYNC_SETTINGS_REQUEST,
  payload,
});

export const getWrikeSyncSettingsSuccess = (payload: load) => ({
  type: type.GET_WRIKE_SYNC_SETTINGS_SUCCESS,
  payload,
});

export const getWrikeSyncSettingsFailure = (payload: load) => ({
  type: type.GET_WRIKE_SYNC_SETTINGS_FAILURE,
  payload,
});

export const updateWrikeSyncSettingsRequest = (payload: load) => ({
  type: type.UPDATE_WRIKE_SYNC_SETTINGS_REQUEST,
  payload,
});

export const updateWrikeSyncSettingsSuccess = (payload: load) => ({
  type: type.UPDATE_WRIKE_SYNC_SETTINGS_SUCCESS,
  payload,
});

export const updateWrikeSyncSettingsFailure = (payload: load) => ({
  type: type.UPDATE_WRIKE_SYNC_SETTINGS_FAILURE,
  payload,
});

export const flushWrikeSyncNotification = () => ({
  type: type.FLUSH_WRIKE_SYNC_NOTIFICATION,
});

export const checkCe4ReadyStatus = (payload: load) => ({
  type: type.CHECK_CE4_READY_STATUS,
  payload,
});

export const checkToggleSyncStatus = (payload: load) => ({
  type: type.CHECK_TOGGLE_SYNC_STATUS,
  payload,
});

export const checkToggleSyncStatusSuccess = (payload: load) => ({
  type: type.CHECK_TOGGLE_SYNC_STATUS_SUCCESS,
  payload,
});

export const checkToggleSyncStatusFailure = (payload: load) => ({
  type: type.CHECK_TOGGLE_SYNC_STATUS_FAILURE,
  payload,
});

export const flushToggleSyncStatus = () => ({
  type: type.FLUSH_TOGGLE_SYNC_STATUS,
});

export const flushManageUsersGrid = () => ({
  type: type.FLUSH_MANAGE_USERS_GRID,
});

export const showLoader = (payload: boolean) => ({
  type: payload ? type.SHOW_LOADER_REQUEST : type.SHOW_LOADER_SUCCESS,
});

export const getProjectActiveFeatures = (payload: load) => ({
  type: type.GET_PROJECT_ACTIVE_FEATURES,
  payload,
});

export const getProjectActiveFeaturesSuccess = (payload: load) => ({
  type: type.GET_PROJECT_ACTIVE_FEATURES_SUCCESS,
  payload,
});

export const getProjectActiveFeaturesFailure = (payload: load) => ({
  type: type.GET_PROJECT_ACTIVE_FEATURES_FAILURE,
  payload,
});

export const extendProjectExpirationRequest = (payload: load) => ({
  type: type.EXTEND_PROJECT_EXPIRATION_REQUEST,
  payload,
});

export const extendProjectExpirationSuccess = (payload: load) => ({
  type: type.EXTEND_PROJECT_EXPIRATION_SUCCESS,
  payload,
});

export const extendProjectExpirationFailure = (payload: load) => ({
  type: type.EXTEND_PROJECT_EXPIRATION_FAILURE,
  payload,
});

export const extendProjectExpirationFlush = () => ({
  type: type.EXTEND_PROJECT_EXPIRATION_FLUSH,
});

export const showExpirationModel = (paylaod) => ({
  type: type.SET_SHOW_EXPIRATION_MODEL,
  paylaod,
});

export const getProjectSupportedFeatures = (payload: load) => ({
  type: type.GET_PROJECT_SUPPORTED_FEATURES_REQUEST,
  payload,
});

export const getProjectSupportedFeaturesSuccess = (payload: load) => ({
  type: type.GET_PROJECT_SUPPORTED_FEATURES_SUCCESS,
  payload,
});

export const getProjectSupportedFeaturesFail = (payload: load) => ({
  type: type.GET_PROJECT_SUPPORTED_FEATURES_FAILURE,
  payload,
});

export const patchProjectUpdateAction = (payload: load) => ({
  type: type.PATCH_PROJECT,
  payload
});

export const addProjectDocumentAction = (payload: load) => ({
  type: type.ADD_PROJECT_DOCUMENT_REQUEST,
  payload,
});

export const deleteProjectDocumentAction = (payload: load) => ({
  type: type.DELETE_PROJECT_DOCUMENT_REQUEST,
  payload,
});