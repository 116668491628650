export const ME = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";
export const ME_FAILURE = "ME_FAILURE";

// create project step2 action types
export const GET_ALL_APPS = "GET_ALL_APPS_REQUEST";
export const GET_ALL_APPS_SUCCESS = "GET_ALL_APPS_SUCCESS";
export const GET_ALL_APPS_FAILURE = "GET_ALL_APPS_FAILURE";

export const GET_ALL_BUNDLES = "GET_ALL_BUNDLES_REQUEST";
export const GET_ALL_BUNDLES_SUCCESS = "GET_ALL_BUNDLES_SUCCESS";
export const GET_ALL_BUNDLES_FAILURE = "GET_ALL_BUNDLES_FAILURE";

export const GET_ALL_APP_CATEGORIES = "GET_ALL_APP_CATEGORIES_REQUEST";
export const GET_ALL_APP_CATEGORIES_SUCCESS = "GET_ALL_APP_CATEGORIES_SUCCESS";
export const GET_ALL_APP_CATEGORIES_FAILURE = "GET_ALL_APP_CATEGORIES_FAILURE";

export const GET_PROJECT_TYPES = "GET_PROJECT_TYPES_REQUEST";
export const GET_PROJECT_TYPES_SUCCESS = "GET_PROJECT_TYPES_SUCCESS";
export const GET_PROJECT_TYPES_FAILURE = "GET_PROJECT_TYPES_FAILURE";

export const UPDATE_PROJECT_TYPE_REQUEST = "UPDATE_PROJECT_TYPE_REQUEST";
export const UPDATE_PROJECT_TYPE_SUCCESS = "UPDATE_PROJECT_TYPE_SUCCESS";
export const UPDATE_PROJECT_TYPE_FAILURE = "UPDATE_PROJECT_TYPE_FAILURE";

export const ADD_PROJECT_TYPE_REQUEST = "ADD_PROJECT_TYPE_REQUEST";
export const ADD_PROJECT_TYPE_SUCCESS = "ADD_PROJECT_TYPE_SUCCESS";
export const ADD_PROJECT_TYPE_FAILURE = "ADD_PROJECT_TYPE_FAILURE";

export const FLUSH_PROJECT_TYPE_DATA = "FLUSH_PROJECT_TYPE_DATA";

export const GET_SECTORS = "GET_SECTORS_REQUEST";
export const GET_SECTORS_SUCCESS = "GET_SECTORS_SUCCESS";
export const GET_SECTORS_FAILURE = "GET_SECTORS_FAILURE";

export const GET_CLIENT_SIZES = "GET_CLIENT_SIZES_REQUEST";
export const GET_CLIENT_SIZES_SUCCESS = "GET_CLIENT_SIZES_SUCCESS";
export const GET_CLIENT_SIZES_FAILURE = "GET_CLIENT_SIZES_FAILURE";

export const GET_DATA_LOCATIONS = "GET_DATA_LOCATIONS_REQUEST";
export const GET_DATA_LOCATIONS_SUCCESS = "GET_DATA_LOCATIONS_SUCCESS";
export const GET_DATA_LOCATIONS_FAILURE = "GET_DATA_LOCATIONS_FAILURE";

export const GET_PROJECT_CATEGORIES = "GET_PROJECT_CATEGORIES_REQUEST";
export const GET_PROJECT_CATEGORIES_SUCCESS = "GET_PROJECT_CATEGORIES_SUCCESS";
export const GET_PROJECT_CATEGORIES_FAILURE = "GET_PROJECT_CATEGORIES_FAILURE";

export const GET_ADMIN_PROJECTS = "GET_ADMIN_PROJECTS_REQUEST";
export const GET_ADMIN_PROJECTS_SUCCESS = "GET_ADMIN_PROJECTS_SUCCESS";
export const GET_ADMIN_PROJECTS_FAILURE = "GET_ADMIN_PROJECTS_FAILURE";

export const UPDATE_ADMIN_PROJECT = "UPDATE_ADMIN_PROJECT_REQUEST";
export const UPDATE_ADMIN_PROJECT_SUCCESS = "UPDATE_ADMIN_PROJECT_SUCCESS";
export const UPDATE_ADMIN_PROJECT_FAILURE = "UPDATE_ADMIN_PROJECT_FAILURE";

export const SET_PROJECT_ACCESS_RESTRICTIONS =
  "SET_PROJECT_ACCESS_RESTRICTIONS_REQUEST";
export const SET_PROJECT_ACCESS_RESTRICTIONS_SUCCESS =
  "SET_PROJECT_ACCESS_RESTRICTIONS_SUCCESS";
export const SET_PROJECT_ACCESS_RESTRICTIONS_FAILURE =
  "SET_PROJECT_ACCESS_RESTRICTIONS_FAILURE";

export const FLUSH_UPDATED_PROJECT_DATA = "FLUSH_UPDATED_PROJECT_DATA";

export const GET_ADMIN_PROJECTS_BY_USER_REQUEST =
  "GET_ADMIN_PROJECTS_BY_USER_REQUEST";
export const GET_ADMIN_PROJECTS_BY_USER_SUCCESS =
  "GET_ADMIN_PROJECTS_BY_USER_SUCCESS";
export const GET_ADMIN_PROJECTS_BY_USER_FAILURE =
  "GET_ADMIN_PROJECTS_BY_USER_FAILURE";
export const FLUSH_ADMIN_PROJECTS_BY_USER = "FLUSH_ADMIN_PROJECTS_BY_USER";

export const SEARCH_ADMIN_PROJECT = "SEARCH_ADMIN_PROJECT";
export const SEARCH_ADMIN_PROJECT_SUCCESS = "SEARCH_ADMIN_PROJECT_SUCCESS";
export const SEARCH_ADMIN_PROJECT_FAILURE = "SEARCH_ADMIN_PROJECT_FAILURE";

export const SEARCH_PROJECT = "SEARCH_PROJECT";
export const SEARCH_PROJECT_SUCCESS = "SEARCH_PROJECT_SUCCESS";
export const SEARCH_PROJECT_FAILURE = "SEARCH_PROJECT_FAILURE";

export const CLEAR_SEARCHED_PROJECT = "CLEAR_SEARCHED_PROJECT";

export const SEARCH_ADMIN_APPS_REQUEST = "SEARCH_ADMIN_APPS";
export const SEARCH_ADMIN_APPS_SUCCESS = "SEARCH_ADMIN_APPS_SUCCESS";
export const SEARCH_ADMIN_APPS_FAILURE = "SEARCH_ADMIN_APPS_FAILURE";

export const SOFT_DELETE_PROJECT = "SOFT_DELETE_PROJECT_REQUEST";
export const SOFT_DELETE_PROJECT_SUCCESS = "SOFT_DELETE_PROJECT_SUCCESS";
export const SOFT_DELETE_PROJECT_FAILURE = "SOFT_DELETE_PROJECT_FAILURE";

export const REACTIVATE_PROJECT = "REACTIVATE_PROJECT_REQUEST";
export const REACTIVATE_PROJECT_SUCCESS = "REACTIVATE_PROJECT_SUCCESS";
export const REACTIVATE_PROJECT_FAILURE = "REACTIVATE_PROJECT_FAILURE";

export const DELETE_ADMIN_PROJECT = "DELETE_ADMIN_PROJECT_REQUEST";
export const DELETE_ADMIN_PROJECT_SUCCESS = "DELETE_ADMIN_PROJECT_SUCCESS";
export const DELETE_ADMIN_PROJECT_FAILURE = "DELETE_ADMIN_PROJECT_FAILURE";

export const FLUSH_ADMIN_PROJECT = "FLUSH_ADMIN_PROJECT";

export const GET_PROJECT_STATUS = "GET_PROJECT_STATUS_REQUEST";
export const GET_PROJECT_STATUS_SUCCESS = "GET_PROJECT_STATUS_SUCCESS";
export const GET_PROJECT_STATUS_FAILURE = "GET_PROJECT_STATUS_FAILURE";

export const CREATE_PROJECT = "CREATE_PROJECT_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";

export const CLEAR_PROJECT_DATA = "CLEAR_PROJECT_DATA";

export const UPDATE_PROJECT = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const PATCH_PROJECT = "PATCH_PROJECT_REQUEST";
export const PATCH_PROJECT_SUCCESS = "PATCH_PROJECT_SUCCESS";
export const PATCH_PROJECT_FAILURE = "PATCH_PROJECT_FAILURE";

export const DELETE_DRAFT_PROJECT_REQUEST = "DELETE_DRAFT_PROJECT_REQUEST";
export const DELETE_DRAFT_PROJECT_SUCCESS = "DELETE_DRAFT_PROJECT_SUCCESS";
export const DELETE_DRAFT_PROJECT_FAILURE = "DELETE_DRAFT_PROJECT_FAILURE";

export const EXTEND_PROJECT_EXPIRATION_REQUEST =
  "EXTEND_PROJECT_EXPIRATION_REQUEST";
export const EXTEND_PROJECT_EXPIRATION_SUCCESS =
  "EXTEND_PROJECT_EXPIRATION_SUCCESS";
export const EXTEND_PROJECT_EXPIRATION_FAILURE =
  "EXTEND_PROJECT_EXPIRATION_FAILURE";
export const EXTEND_PROJECT_EXPIRATION_FLUSH =
  "EXTEND_PROJECT_EXPIRATION_FLUSH";

export const FLUSH_DELETED_PROJECT = "FLUSH_DELETED_PROJECT";

export const GET_PROJECT_LIST_REQUEST = "GET_PROJECT_LIST_REQUEST";
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS";
export const GET_PROJECT_LIST_FAILURE = "GET_PROJECT_LIST_FAILURE";
export const GET_PROJECTS_SUMMARY_REQUEST = "GET_PROJECTS_SUMMARY_REQUEST";
export const GET_PROJECTS_SUMMARY_SUCCESS = "GET_PROJECTS_SUMMARY_SUCCESS";
export const GET_PROJECTS_SUMMARY_FAILURE = "GET_PROJECTS_SUMMARY_FAILURE";
export const GET_PROJECT_DETAILS_REQUEST = "GET_PROJECT_DETAILS_REQUEST";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_FAILURE = "GET_PROJECT_DETAILS_FAILURE";

export const GET_PROJECT_METADATA_REQUEST = "GET_PROJECT_METADATA_REQUEST";
export const GET_PROJECT_METADATA_SUCCESS = "GET_PROJECT_METADATA_SUCCESS";
export const GET_PROJECT_METADATA_FAILURE = "GET_PROJECT_METADATA_FAILURE";

export const GET_PROJECT_SUPPORTED_FEATURES_REQUEST = "GET_PROJECT_SUPPORTED_FEATURES_REQUEST";
export const GET_PROJECT_SUPPORTED_FEATURES_SUCCESS = "GET_PROJECT_SUPPORTED_FEATURES_SUCCESS";
export const GET_PROJECT_SUPPORTED_FEATURES_FAILURE = "GET_PROJECT_SUPPORTED_FEATURES_FAILURE";

export const FLUSH_PROJECT_DETAILS = "FLUSH_PROJECT_DETAILS";

export const CHECK_CE4_READY_STATUS = "CHECK_CE4_READY_STATUS";

export const UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS =
  "UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS";
export const UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_REQUEST =
  "UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_REQUEST";
export const UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_SUCCESS =
  "UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_SUCCESS";
export const UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_FAILURE =
  "UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_FAILURE";
export const GET_PROJECT_USERS_LIST_REQUEST = "GET_PROJECT_USERS_LIST_REQUEST";
export const GET_PROJECT_USERS_LIST_SUCCESS = "GET_PROJECT_USERS_LIST_SUCCESS";
export const GET_PROJECT_USERS_LIST_FAILURE = "GET_PROJECT_USERS_LIST_FAILURE";
export const GET_PROJECT_USERS_ACCESS_LIST_REQUEST =
  "GET_PROJECT_USERS_ACCESS_LIST_REQUEST";
export const GET_PROJECT_USERS_ACCESS_LIST_SUCCESS =
  "GET_PROJECT_USERS_ACCESS_LIST_SUCCESS";
export const GET_PROJECT_USERS_ACCESS_LIST_FAILURE =
  "GET_PROJECT_USERS_ACCESS_LIST_FAILURE";

export const GET_ALL_APP_PLATFORMS = "GET_ALL_APP_PLATFORMS_REQUEST";
export const GET_ALL_APP_PLATFORMS_SUCCESS = "GET_ALL_APP_PLATFORMS_SUCCESS";
export const GET_ALL_APP_PLATFORMS_FAILURE = "GET_ALL_APP_PLATFORMS_FAILURE";

export const SEARCH_MANAGE_USER = "SEARCH_MANAGE_USER_REQUEST";
export const SEARCH_MANAGE_USER_SUCCESS = "SEARCH_MANAGE_USER_SUCCESS";
export const SEARCH_MANAGE_USER_FAILURE = "SEARCH_MANAGE_USER_FAILURE";
export const FLUSH_MANAGE_USER = "FLUSH_MANAGE_USER";
export const ADD_MANAGE_USER_REQUEST = "ADD_MANAGE_USER_REQUEST";
export const ADD_MANAGE_USER_SUCCESS = "ADD_MANAGE_USER_SUCCESS";
export const ADD_MANAGE_USER_FAILURE = "ADD_MANAGE_USER_FAILURE";
export const REMOVE_MANANGE_USER = "REMOVE_MANANGE_USER";
export const UPDATE_MANAGE_USER_REQUEST = "UPDATE_MANAGE_USER_REQUEST";
export const UPDATE_MANAGE_USER_SUCCESS = "UPDATE_MANAGE_USER_SUCCESS";
export const UPDATE_MANAGE_USER_FAILURE = "UPDATE_MANAGE_USER_FAILURE";
export const UPDATE_MANAGE_USER_RESET = "UPDATE_MANAGE_USER_RESET";
export const UPLOAD_MANAGE_USER_BULK_FILE_REQUEST =
  "UPLOAD_MANAGE_USER_BULK_FILE_REQUEST";
export const UPLOAD_MANAGE_USER_BULK_FILE_SUCCESS =
  "UPLOAD_MANAGE_USER_BULK_FILE_SUCCESS";
export const UPLOAD_MANAGE_USER_BULK_FILE_FAILURE =
  "UPLOAD_MANAGE_USER_BULK_FILE_FAILURE";

export const UPDATE_ACTIVE_APPS = "UPDATE_ACTIVE_APPS";

export const GET_UPDATED_BUNDLES = "GET_UPDATED_BUNDLES";

export const FLUSH_UPDATED_BUNDLES = "FLUSH_UPDATED_BUNDLES";

export const GET_ALL_PLATFORM_ROLES_REQUEST = "GET_ALL_PLATFORM_ROLES_REQUEST";
export const GET_ALL_PLATFORM_ROLES_SUCCESS = "GET_ALL_PLATFORM_ROLES_SUCCESS";
export const GET_ALL_PLATFORM_ROLES_FAILURE = "GET_ALL_PLATFORM_ROLES_FAILURE";

export const SEARCH_MANAGE_USER_GRID = "SEARCH_MANAGE_USER_GRID";
export const SEARCH_MANAGE_USER_GRID_SUCCESS =
  "SEARCH_MANAGE_USER_GRID_SUCCESS";
export const SEARCH_MANAGE_USER_GRID_FAILURE =
  "SEARCH_MANAGE_USER_GRID_FAILURE";
export const CREATE_OWN_BUNDLE = "CREATE_OWN_BUNDLE";

export const FLUSH_CREATED_BUNDLE = "FLUSH_CREATED_BUNDLE";
export const CLOSE_PROJECT_REQUEST = "CLOSE_PROJECT_REQUEST";
export const CLOSE_PROJECT_SUCCESS = "CLOSE_PROJECT_SUCCESS";
export const CLOSE_PROJECT_FAILURE = "CLOSE_PROJECT_FAILURE";
export const FLUSH_CLOSE_PROJECT = "FLUSH_CLOSE_PROJECT";

export const CREATE_ANNOUNCEMENT_REQUEST = "CREATE_ANNOUNCEMENT_REQUEST";
export const CREATE_ANNOUNCEMENT_SUCCESS = "CREATE_ANNOUNCEMENT_SUCCESS";
export const CREATE_ANNOUNCEMENT_FAILURE = "CREATE_ANNOUNCEMENT_FAILURE";

export const FLUSH_ANNOUCEMENT_NOTIFICATION = "FLUSH_ANNOUCEMENT_NOTIFICATION";

export const GET_SSP_ROLES_REQUEST = "GET_SSP_ROLES_REQUEST";
export const GET_SSP_ROLES_SUCCESS = "GET_SSP_ROLES_SUCCESS";
export const GET_SSP_ROLES_FAILURE = "GET_SSP_ROLES_FAILURE";

export const GET_SERVICE_LINES_REQUEST = "GET_SERVICE_LINES_REQUEST";
export const GET_SERVICE_LINES_SUCCESS = "GET_SERVICE_LINES_SUCCESS";
export const GET_SERVICE_LINES_FAILURE = "GET_SERVICE_LINES_FAILURE";

export const GET_SUB_SERVICE_LINES_REQUEST = "GET_SUB_SERVICE_LINES_REQUEST";
export const GET_SUB_SERVICE_LINES_SUCCESS = "GET_SUB_SERVICE_LINES_SUCCESS";
export const GET_SUB_SERVICE_LINES_FAILURE = "GET_SUB_SERVICE_LINES_FAILURE";

export const GET_PAANS_POLICY_REQUEST = "GET_PAANS_POLICY_REQUEST";
export const GET_PAANS_POLICY_SUCCESS = "GET_PAANS_POLICY_SUCCESS";
export const GET_PAANS_POLICY_FAILURE = "GET_PAANS_POLICY_FAILURE";

export const GET_ANNOUNCEMENTS_CHUNK = "GET_ANNOUNCEMENTS_CHUNK";
export const GET_ANNOUNCEMENTS_SUCCESS = "GET_ANNOUNCEMENTS_SUCCESS";
export const GET_ANNOUNCEMENTS_FAILURE = "GET_ANNOUNCEMENTS_FAILURE";

export const FLUSH_ANNOUNCEMENTS = "FLUSH_ANNOUNCEMENTS";

export const GET_MY_ACCESS_RIGHT_REQUEST = "GET_MY_ACCESS_RIGHT_REQUEST";
export const GET_MY_ACCESS_RIGHT_SUCCESS = "GET_MY_ACCESS_RIGHT_SUCCESS";
export const GET_MY_ACCESS_RIGHT_FAILURE = "GET_MY_ACCESS_RIGHT_FAILURE";

export const UPDATE_ANNOUNCEMENT_REQUEST = "UPDATE_ANNOUNCEMENT_REQUEST";
export const UPDATE_ANNOUNCEMENT_SUCCESS = "UPDATE_ANNOUNCEMENT_SUCCESS";
export const UPDATE_ANNOUNCEMENT_FAILURE = "UPDATE_ANNOUNCEMENT_FAILURE";

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const GET_MY_PROJECT_RIGHT_REQUEST = "GET_MY_PROJECT_RIGHT_REQUEST";
export const GET_MY_PROJECT_RIGHT_SUCCESS = "GET_MY_PROJECT_RIGHT_SUCCESS";
export const GET_MY_PROJECT_RIGHT_FAILURE = "GET_MY_PROJECT_RIGHT_FAILURE";

export const DELETE_ANNOUNCEMENT_REQUEST = "DELETE_ANNOUNCEMENT_REQUEST";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_FAILURE = "DELETE_ANNOUNCEMENT_FAILURE";

export const SHOW_PREVIEW_VIDEO = "SHOW_PREVIEW_VIDEO";
export const HIDE_PREVIEW_VIDEO = "HIDE_PREVIEW_VIDEO";

export const GET_PROJECT_AREAS = "GET_PROJECT_AREAS";
export const GET_PROJECT_AREAS_SUCCESS = "GET_PROJECT_AREAS_SUCCESS";
export const GET_PROJECT_AREAS_FAILURE = "GET_PROJECT_AREAS_FAILURE";

export const GET_PROJECT_REGIONS = "GET_PROJECT_REGIONS";
export const GET_PROJECT_REGIONS_SUCCESS = "GET_PROJECT_REGIONS_SUCCESS";
export const GET_PROJECT_REGIONS_FAILURE = "GET_PROJECT_REGIONS_FAILURE";

export const GET_PROJECT_COUNTRIES_CODE = "GET_PROJECT_COUNTRIES_CODE";
export const GET_PROJECT_COUNTRIES_CODE_SUCCESS =
  "GET_PROJECT_COUNTRIES_CODE_SUCCESS";
export const GET_PROJECT_COUNTRIES_CODE_FAILURE =
  "GET_PROJECT_COUNTRIES_CODE_FAILURE";

export const GET_PROJECT_ACTIVE_FEATURES = "GET_PROJECT_ACTIVE_FEATURES";
export const GET_PROJECT_ACTIVE_FEATURES_SUCCESS =
  "GET_PROJECT_ACTIVE_FEATURES_SUCCESS";
export const GET_PROJECT_ACTIVE_FEATURES_FAILURE =
  "GET_PROJECT_ACTIVE_FEATURES_FAILURE";

export const ADD_PROJECT_APPS_REQUEST = "ADD_PROJECT_APPS_REQUEST";
export const ADD_PROJECT_APPS_SUCCESS = "ADD_PROJECT_APPS_SUCCESS";
export const ADD_PROJECT_APPS_FAILURE = "ADD_PROJECT_APPS_FAILURE";

export const UPDATE_PROJECT_APPS_REQUEST = "UPDATE_PROJECT_APPS_REQUEST";
export const UPDATE_PROJECT_APPS_SUCCESS = "UPDATE_PROJECT_APPS_SUCCESS";
export const UPDATE_PROJECT_APPS_FAILURE = "UPDATE_PROJECT_APPS_FAILURE";

export const GET_POWERBI_REPORTS_REQUEST = "GET_POWERBI_REPORTS_REQUEST";
export const GET_POWERBI_REPORTS_SUCCESS = "GET_POWERBI_REPORTS_SUCCESS";
export const GET_POWERBI_REPORTS_FAILURE = "GET_POWERBI_REPORTS_FAILURE";
export const GET_POWERBI_TOKEN_REQUEST = "GET_POWERBI_TOKEN_REQUEST";
export const GET_POWERBI_TOKEN_SUCCESS = "GET_POWERBI_TOKEN_SUCCESS";
export const GET_POWERBI_TOKEN_FAILURE = "GET_POWERBI_TOKEN_FAILURE";
export const GET_ADMIN_REPORTS_REQUEST = "GET_ADMIN_REPORTS_REQUEST";
export const GET_ADMIN_REPORTS_SUCCESS = "GET_ADMIN_REPORTS_SUCCESS";
export const GET_ADMIN_REPORTS_FAILURE = "GET_ADMIN_REPORTS_FAILURE";
export const ADD_ADMIN_REPORT_REQUEST = "ADD_ADMIN_REPORT_REQUEST";
export const ADD_ADMIN_REPORT_SUCCESS = "ADD_ADMIN_REPORT_SUCCESS";
export const ADD_ADMIN_REPORT_FAILURE = "ADD_ADMIN_REPORT_FAILURE";
export const UPDATE_ADMIN_REPORT_REQUEST = "UPDATE_ADMIN_REPORT_REQUEST";
export const UPDATE_ADMIN_REPORT_SUCCESS = "UPDATE_ADMIN_REPORT_SUCCESS";
export const UPDATE_ADMIN_REPORT_FAILURE = "UPDATE_ADMIN_REPORT_FAILURE";
export const DELETE_ADMIN_REPORT_REQUEST = "DELETE_ADMIN_REPORT_REQUEST";
export const DELETE_ADMIN_REPORT_SUCCESS = "DELETE_ADMIN_REPORT_SUCCESS";
export const DELETE_ADMIN_REPORT_FAILURE = "DELETE_ADMIN_REPORT_FAILURE";

export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";
export const SEARCH_ADMIN_USER_GRID = "SEARCH_ADMIN_USER_GRID";
export const SEARCH_ADMIN_USER_GRID_SUCCESS = "SEARCH_ADMIN_USER_GRID_SUCCESS";
export const SEARCH_ADMIN_USER_GRID_FAILURE = "SEARCH_ADMIN_USER_GRID_FAILURE";

export const GET_ADMIN_APPS_REQUEST = "GET_ADMIN_APPS_REQUEST";
export const GET_ADMIN_APPS_SUCCESS = "GET_ADMIN_APPS_SUCCESS";
export const GET_ADMIN_APPS_FAILURE = "GET_ADMIN_APPS_FAILURE";

export const APP_ERROR_HANDLER_REQUEST = "APP_ERROR_HANDLER_REQUEST";
export const APP_ERROR_HANDLER_SUCCESS = "APP_ERROR_HANDLER_SUCCESS";
export const APP_ERROR_HANDLER_FAILURE = "APP_ERROR_HANDLER_FAILURE";

export const FLUSH_APP_ERROR_NOTIFICATION = "FLUSH_APP_ERROR_NOTIFICATION";

export const DELETE_ADMIN_APP_REQUEST = "DELETE_ADMIN_APP_REQUEST";
export const DELETE_ADMIN_APP_SUCCESS = "DELETE_ADMIN_APP_SUCCESS";
export const DELETE_ADMIN_APP_FAILURE = "DELETE_ADMIN_APP_FAILURE";

export const FLUSH_ADMIN_APP = "FLUSH_ADMIN_APP";

export const UPDATE_ADMIN_USER_GRID_REQUEST = "UPDATE_ADMIN_USER_GRID_REQUEST";
export const UPDATE_ADMIN_USER_GRID_SUCCESS = "UPDATE_ADMIN_USER_GRID_SUCCESS";
export const UPDATE_ADMIN_USER_GRID_FAILURE = "UPDATE_ADMIN_USER_GRID_FAILURE";

export const VALIDATE_ADMIN_USER_REQUEST = "VALIDATE_ADMIN_USER_REQUEST";
export const VALIDATE_ADMIN_USER_SUCCESS = "VALIDATE_ADMIN_USER_SUCCESS";

export const GET_ADMIN_APPS_VIDEO_REQUEST = "GET_ADMIN_APPS_VIDEO_REQUEST";
export const GET_ADMIN_APPS_VIDEO_SUCCESS = "GET_ADMIN_APPS_VIDEO_SUCCESS";
export const GET_ADMIN_APPS_VIDEO_FAILURE = "GET_ADMIN_APPS_VIDEO_FAILURE";

export const GET_ADMIN_APPS_THUMBNAIL_REQUEST = "GET_ADMIN_APPS_THUMBNAIL_REQUEST";
export const GET_ADMIN_APPS_THUMBNAIL_SUCCESS = "GET_ADMIN_APPS_THUMBNAIL_SUCCESS";
export const GET_ADMIN_APPS_THUMBNAIL_FAILURE = "GET_ADMIN_APPS_THUMBNAIL_FAILURE";

export const GET_PROJECT_LOGS_REQUEST = "GET_PROJECT_LOGS_REQUEST";
export const GET_PROJECT_LOGS_SUCCESS = "GET_PROJECT_LOGS_SUCCESS";
export const GET_PROJECT_LOGS_FAILURE = "GET_PROJECT_LOGS_FAILURE";

export const SEARCH_PROJECT_LOGS = "SEARCH_PROJECT_LOGS";
export const SEARCH_PROJECT_LOGS_SUCCESS = "SEARCH_PROJECT_LOGS_SUCCESS";
export const SEARCH_PROJECT_LOGS_FAILURE = "SEARCH_PROJECT_LOGS_FAILURE";

export const DELETE_ADMIN_APPS_VIDEO_REQUEST = "DELETE_ADMIN_APPS_VIDEO_REQUEST";
export const DELETE_ADMIN_APPS_VIDEO_SUCCESS = "DELETE_ADMIN_APPS_VIDEO_SUCCESS";
export const DELETE_ADMIN_APPS_VIDEO_FAILURE = "DELETE_ADMIN_APPS_VIDEO_FAILURE";

export const DELETE_ADMIN_APPS_THUMBNAIL_REQUEST = "DELETE_ADMIN_APPS_THUMBNAIL_REQUEST";
export const DELETE_ADMIN_APPS_THUMBNAIL_SUCCESS = "DELETE_ADMIN_APPS_THUMBNAIL_SUCCESS";
export const DELETE_ADMIN_APPS_THUMBNAIL_FAILURE = "DELETE_ADMIN_APPS_THUMBNAIL_FAILURE";

export const GET_MAINTENANCE_STATUS_REQUEST = "GET_MAINTENANCE_STATUS_REQUEST";
export const GET_MAINTENANCE_STATUS_SUCCESS = "GET_MAINTENANCE_STATUS_SUCCESS";
export const GET_MAINTENANCE_STATUS_FAILURE = "GET_MAINTENANCE_STATUS_FAILURE";

export const SCHEDULE_MAINTENANACE_REQUEST = "SCHEDULE_MAINTENANACE_REQUEST";
export const SCHEDULE_MAINTENANACE_SUCCESS = "SCHEDULE_MAINTENANACE_SUCCESS";
export const SCHEDULE_MAINTENANACE_FAILURE = "SCHEDULE_MAINTENANACE_FAILURE";

export const FLUSH_MAINTENANCE_NOTIFICATION = "FLUSH_MAINTENANCE_NOTIFICATION";

export const BULK_TEMPLATE_DOWNLOAD = "BULK_TEMPLATE_DOWNLOAD";
export const BULK_TEMPLATE_DOWNLOAD_SUCCESS = "BULK_TEMPLATE_DOWNLOAD_SUCCESS";
export const BULK_TEMPLATE_DOWNLOAD_FAILURE = "BULK_TEMPLATE_DOWNLOAD_FAILURE";

export const UPDATE_FILTER_STATE = "UPDATE_FILTER_STATE";

export const FLUSH_FILTER_STATE = "FLUSH_FILTER_STATE";

export const FLUSH_PROJECT_LOGS = "FLUSH_PROJECT_LOGS";

export const GET_ACCEPTED_POLICY_REQUEST = "GET_ACCEPTED_POLICY_REQUEST";
export const GET_ACCEPTED_POLICY_SUCCESS = "GET_ACCEPTED_POLICY_SUCCESS";
export const GET_ACCEPTED_POLICY_FAILURE = "GET_ACCEPTED_POLICY_FAILURE";

export const GET_PROJECT_ICONS_REQUEST = "GET_PROJECT_ICONS_REQUEST";
export const GET_PROJECT_ICONS_SUCCESS = "GET_PROJECT_ICONS_SUCCESS";
export const GET_PROJECT_ICONS_FAILURE = "GET_PROJECT_ICONS_FAILURE";

export const GET_PROJECT_EMG_POLICY_REQUEST = "GET_PROJECT_EMG_POLICY_REQUEST";
export const GET_PROJECT_EMG_POLICY_SUCCESS = "GET_PROJECT_EMG_POLICY_SUCCESS";
export const GET_PROJECT_EMG_POLICY_FAILURE = "GET_PROJECT_EMG_POLICY_FAILURE";

export const ACCEPT_PROJECT_EMG_POLICY_REQUEST =
  "ACCEPT_PROJECT_EMG_POLICY_REQUEST";
export const ACCEPT_PROJECT_EMG_POLICY_SUCCESS =
  "ACCEPT_PROJECT_EMG_POLICY_SUCCESS";
export const ACCEPT_PROJECT_EMG_POLICY_FAILURE =
  "ACCEPT_PROJECT_EMG_POLICY_FAILURE";

export const UPDATE_PROJECT_EMG_POLICY_PAYLOAD =
  "UPDATE_PROJECT_EMG_POLICY_PAYLOAD";

export const GET_WRIKE_SYNC_SETTINGS_REQUEST =
  "GET_WRIKE_SYNC_SETTINGS_REQUEST";
export const GET_WRIKE_SYNC_SETTINGS_SUCCESS =
  "GET_WRIKE_SYNC_SETTINGS_SUCCESS";
export const GET_WRIKE_SYNC_SETTINGS_FAILURE =
  "GET_WRIKE_SYNC_SETTINGS_FAILURE";

export const UPDATE_WRIKE_SYNC_SETTINGS_REQUEST =
  "UPDATE_WRIKE_SYNC_SETTINGS_REQUEST";
export const UPDATE_WRIKE_SYNC_SETTINGS_SUCCESS =
  "UPDATE_WRIKE_SYNC_SETTINGS_SUCCESS";
export const UPDATE_WRIKE_SYNC_SETTINGS_FAILURE =
  "UPDATE_WRIKE_SYNC_SETTINGS_FAILURE";

export const FLUSH_WRIKE_SYNC_NOTIFICATION = "FLUSH_WRIKE_SYNC_NOTIFICATION";

export const GET_PROJECT_DEFAULT_ROLES_REQUEST =
  "GET_PROJECT_DEFAULT_ROLES_REQUEST";
export const GET_PROJECT_DEFAULT_ROLES_SUCCESS =
  "GET_PROJECT_DEFAULT_ROLES_SUCCESS";
export const GET_PROJECT_DEFAULT_ROLES_FAILURE =
  "GET_PROJECT_DEFAULT_ROLES_FAILURE";

export const ENROLL_USER_DEMO_PROJECT_REQUEST =
  "ENROLL_USER_DEMO_PROJECT_REQUEST";
export const ENROLL_USER_DEMO_PROJECT_SUCCESS =
  "ENROLL_USER_DEMO_PROJECT_SUCCESS";
export const ENROLL_USER_DEMO_PROJECT_FAILURE =
  "ENROLL_USER_DEMO_PROJECT_FAILURE";

export const UPDATE_CUSTOMIZATION_INFO_REQUEST =
  "UPDATE_CUSTOMIZATION_INFO_REQUEST";
export const UPDATE_CUSTOMIZATION_INFO_SUCCESS =
  "UPDATE_CUSTOMIZATION_INFO_SUCCESS";
export const UPDATE_CUSTOMIZATION_INFO_FAILURE =
  "UPDATE_CUSTOMIZATION_INFO_FAILURE";

export const CHECK_TOGGLE_SYNC_STATUS = "CHECK_TOGGLE_SYNC_STATUS";
export const CHECK_TOGGLE_SYNC_STATUS_SUCCESS =
  "CHECK_TOGGLE_SYNC_STATUS_SUCCESS";
export const CHECK_TOGGLE_SYNC_STATUS_FAILURE =
  "CHECK_TOGGLE_SYNC_STATUS_FAILURE";

export const FLUSH_TOGGLE_SYNC_STATUS = "FLUSH_TOGGLE_SYNC_STATUS";

export const EXPORT_PROJECT_LIST = "EXPORT_PROJECT_LIST";
export const EXPORT_PROJECT_LIST_SUCCESS = "EXPORT_PROJECT_LIST_SUCCESS";
export const EXPORT_PROJECT_LIST_FAILURE = "EXPORT_PROJECT_LIST_FAILURE";

export const FLUSH_EXPORT_PROJECT_LIST_STATUS =
  "FLUSH_EXPORT_PROJECT_LIST_STATUS";

export const GET_ADMIN_REGIONS_REQUEST = "GET_ADMIN_REGIONS_REQUEST";
export const GET_ADMIN_REGIONS_SUCCESS = "GET_ADMIN_REGIONS_SUCCESS";
export const GET_ADMIN_REGIONS_FAILURE = "GET_ADMIN_REGIONS_FAILURE";

export const UPDATE_ADMIN_REGIONS_REQUEST = "UPDATE_ADMIN_REGIONS_REQUEST";
export const UPDATE_ADMIN_REGIONS_SUCCESS = "UPDATE_ADMIN_REGIONS_SUCCESS";
export const UPDATE_ADMIN_REGIONS_FAILURE = "UPDATE_ADMIN_REGIONS_FAILURE";

export const GET_ADMIN_CLIENT_SIZES_REQUEST = "GET_ADMIN_CLIENT_SIZES_REQUEST";
export const GET_ADMIN_CLIENT_SIZES_SUCCESS = "GET_ADMIN_CLIENT_SIZES_SUCCESS";
export const GET_ADMIN_CLIENT_SIZES_FAILURE = "GET_ADMIN_CLIENT_SIZES_FAILURE";

export const UPDATE_ADMIN_CLIENT_SIZES_REQUEST =
  "UPDATE_ADMIN_CLIENT_SIZES_REQUEST";
export const UPDATE_ADMIN_CLIENT_SIZES_SUCCESS =
  "UPDATE_ADMIN_CLIENT_SIZES_SUCCESS";
export const UPDATE_ADMIN_CLIENT_SIZES_FAILURE =
  "UPDATE_ADMIN_CLIENT_SIZES_FAILURE";

export const GET_ADMIN_SECTORS_REQUEST = "GET_ADMIN_SECTORS_REQUEST";
export const GET_ADMIN_SECTORS_SUCCESS = "GET_ADMIN_SECTORS_SUCCESS";
export const GET_ADMIN_SECTORS_FAILURE = "GET_ADMIN_SECTORS_FAILURE";

export const UPDATE_ADMIN_SECTORS_REQUEST = "UPDATE_ADMIN_SECTORS_REQUEST";
export const UPDATE_ADMIN_SECTORS_SUCCESS = "UPDATE_ADMIN_SECTORS_SUCCESS";
export const UPDATE_ADMIN_SECTORS_FAILURE = "UPDATE_ADMIN_SECTORS_FAILURE";

export const GET_ADMIN_DATA_HOSTING_LOC_REQUEST =
  "GET_ADMIN_DATA_HOSTING_LOC_REQUEST";
export const GET_ADMIN_DATA_HOSTING_LOC_SUCCESS =
  "GET_ADMIN_DATA_HOSTING_LOC_SUCCESS";
export const GET_ADMIN_DATA_HOSTING_LOC_FAILURE =
  "GET_ADMIN_DATA_HOSTING_LOC_FAILURE";

export const GET_ADMIN_TYPES_REQUEST = "GET_ADMIN_TYPES_REQUEST";
export const GET_ADMIN_TYPES_SUCCESS = "GET_ADMIN_TYPES_SUCCESS";
export const GET_ADMIN_TYPES_FAILURE = "GET_ADMIN_TYPES_FAILURE";

export const UPDATE_ADMIN_TYPES_REQUEST = "UPDATE_ADMIN_TYPES_REQUEST";
export const UPDATE_ADMIN_TYPES_SUCCESS = "UPDATE_ADMIN_TYPES_SUCCESS";
export const UPDATE_ADMIN_TYPES_FAILURE = "UPDATE_ADMIN_TYPES_FAILURE";

export const GET_ADMIN_SCHEDULE_REQUEST = "GET_ADMIN_SCHEDULE_REQUEST";
export const GET_ADMIN_SCHEDULE_SUCCESS = "GET_ADMIN_SCHEDULE_SUCCESS";
export const GET_ADMIN_SCHEDULE_FAILURE = "GET_ADMIN_SCHEDULE_FAILURE";

export const ADD_ADMIN_SCHEDULE_REQUEST = "ADD_ADMIN_SCHEDULE_REQUEST";
export const ADD_ADMIN_SCHEDULE_SUCCESS = "ADD_ADMIN_SCHEDULE_SUCCESS";
export const ADD_ADMIN_SCHEDULE_FAILURE = "ADD_ADMIN_SCHEDULE_FAILURE";

export const UPDATE_ADMIN_SCHEDULE_REQUEST = "UPDATE_ADMIN_SCHEDULE_REQUEST";
export const UPDATE_ADMIN_SCHEDULE_SUCCESS = "UPDATE_ADMIN_SCHEDULE_SUCCESS";
export const UPDATE_ADMIN_SCHEDULE_FAILURE = "UPDATE_ADMIN_SCHEDULE_FAILURE";

export const DELETE_ADMIN_SCHEDULE_REQUEST = "DELETE_ADMIN_SCHEDULE_REQUEST";
export const DELETE_ADMIN_SCHEDULE_SUCCESS = "DELETE_ADMIN_SCHEDULE_SUCCESS";
export const DELETE_ADMIN_SCHEDULE_FAILURE = "DELETE_ADMIN_SCHEDULE_FAILURE";

export const FLUSH_MANAGE_USERS_GRID = "FLUSH_MANAGE_USERS_GRID";

export const SHOW_LOADER_REQUEST = "SHOW_LOADER_REQUEST";
export const SHOW_LOADER_SUCCESS = "SHOW_LOADER_SUCCESS";

export const GET_CPS_WORKFLOWS = "GET_CPS_WORKFLOWS_REQUEST";
export const GET_CPS_WORKFLOWS_SUCCESS = "GET_CPS_WORKFLOWS_SUCCESS";
export const GET_CPS_WORKFLOWS_FAILURE = "GET_CPS_WORKFLOWS_FAILURE";
export const FLUSH_CPS_WORKFLOWS_DATA = "FLUSH_CPS_WORKFLOWS_DATA";

export const SEARCH_CPS_WORKFLOWS_REQUEST = "SEARCH_CPS_WORKFLOWS";
export const SEARCH_CPS_WORKFLOWS_SUCCESS = "SEARCH_CPS_WORKFLOWS_SUCCESS";
export const SEARCH_CPS_WORKFLOWS_FAILURE = "SEARCH_CPS_WORKFLOWS_FAILURE";
export const FLUSH_SEARCH_CPS_WORKFLOWS = "FLUSH_SEARCH_CPS_WORKFLOWS";

export const ADD_CPS_WORKFLOWS_REQUEST = "ADD_CPS_WORKFLOWS_REQUEST";
export const ADD_CPS_WORKFLOWS_SUCCESS = "ADD_CPS_WORKFLOWS_SUCCESS";
export const ADD_CPS_WORKFLOWS_FAILURE = "ADD_CPS_WORKFLOWS_FAILURE";

export const UPDATE_CPS_WORKFLOWS_REQUEST = "UPDATE_CPS_WORKFLOWS_REQUEST";
export const UPDATE_CPS_WORKFLOWS_SUCCESS = "UPDATE_CPS_WORKFLOWS_SUCCESS";
export const UPDATE_CPS_WORKFLOWS_FAILURE = "UPDATE_CPS_WORKFLOWS_FAILURE";

export const DELETE_CPS_WORKFLOWS_REQUEST = "DELETE_CPS_WORKFLOWS_REQUEST";
export const DELETE_CPS_WORKFLOWS_SUCCESS = "DELETE_CPS_WORKFLOWS_SUCCESS";
export const DELETE_CPS_WORKFLOWS_FAILURE = "DELETE_CPS_WORKFLOWS_FAILURE";
export const FLUSH_DELETE_CPS_WORKFLOWS_DATA =
  "FLUSH_DELETE_CPS_WORKFLOWS_DATA";

export const FLUSH_UPDATED_WORKFLOWS_DATA = "FLUSH_UPDATED_WORKFLOWS_DATA";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export const GET_CPS_COMM_TEMPLATE_CATEGORY =
  "GET_CPS_COMM_TEMPLATE_CATEGORY_REQUEST";
export const GET_CPS_COMM_TEMPLATE_CATEGORY_SUCCESS =
  "GET_CPS_COMM_TEMPLATE_CATEGORY_SUCCESS";
export const GET_CPS_COMM_TEMPLATE_CATEGORY_FAILURE =
  "GET_CPS_COMM_TEMPLATE_CATEGORY_FAILURE";
export const FLUSH_CPS_COMM_TEMPLATE_CATEGORY_DATA =
  "FLUSH_CPS_COMM_TEMPLATE_CATEGORY_DATA";

export const GET_CPS_WORKFLOW_DEFINITIONS =
  "GET_CPS_WORKFLOW_DEFINITIONS_REQUEST";
export const GET_CPS_WORKFLOW_DEFINITIONS_SUCCESS =
  "GET_CPS_WORKFLOW_DEFINITIONS_SUCCESS";
export const GET_CPS_WORKFLOW_DEFINITIONS_FAILURE =
  "GET_CPS_WORKFLOW_DEFINITIONS_FAILURE";
export const FLUSH_CPS_WORKFLOW_DEFINITIONS_DATA =
  "FLUSH_CPS_WORKFLOW_DEFINITIONS_DATA";

export const GET_CPS_COMM_TEMPLATES = "GET_CPS_COMM_TEMPLATES_REQUEST";
export const GET_CPS_COMM_TEMPLATES_SUCCESS = "GET_CPS_COMM_TEMPLATES_SUCCESS";
export const GET_CPS_COMM_TEMPLATES_FAILURE = "GET_CPS_COMM_TEMPLATES_FAILURE";
export const FLUSH_CPS_COMM_TEMPLATES_DATA = "FLUSH_CPS_COMM_TEMPLATES_DATA";

export const GET_CPS_WORKFLOW_SCHEDULE = "GET_CPS_WORKFLOW_SCHEDULE_REQUEST";
export const GET_CPS_WORKFLOW_SCHEDULE_SUCCESS =
  "GET_CPS_WORKFLOW_SCHEDULE_SUCCESS";
export const GET_CPS_WORKFLOW_SCHEDULE_FAILURE =
  "GET_CPS_WORKFLOW_SCHEDULE_FAILURE";
export const FLUSH_CPS_WORKFLOW_SCHEDULE_DATA =
  "FLUSH_CPS_WORKFLOW_SCHEDULE_DATA";

export const GET_CPS_WORKFLOW_PROJECT = "GET_CPS_WORKFLOW_PROJECT_REQUEST";
export const GET_CPS_WORKFLOW_PROJECT_SUCCESS =
  "GET_CPS_WORKFLOW_PROJECT_SUCCESS";
export const GET_CPS_WORKFLOW_PROJECT_FAILURE =
  "GET_CPS_WORKFLOW_PROJECT_FAILURE";
export const FLUSH_CPS_WORKFLOW_PROJECT_DATA =
  "FLUSH_CPS_WORKFLOW_PROJECT_DATA";

export const START_CPS_WORKFLOW = "START_CPS_WORKFLOW_REQUEST";
export const START_CPS_WORKFLOW_SUCCESS = "START_CPS_WORKFLOW_SUCCESS";
export const START_CPS_WORKFLOW_FAILURE = "START_CPS_WORKFLOW_FAILURE";
export const FLUSH_START_CPS_WORKFLOW_DATA = "FLUSH_START_CPS_WORKFLOW_DATA";
/** portal communication feature **/
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_GENERAL_NOTIFICATIONS_SUCCESS =
  "GET_GENERAL_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE =
  "GET_NOTIFICATIONS_FAILURE";
export const CREATE_NOTIFICATION="CREATE_NOTIFICATION";
export const CREATE_GENERAL_NOTIFICATION = "CREATE_GENERAL_NOTIFICATION";
export const CREATE_GENERAL_NOTIFICATION_SUCCESS =
  "CREATE_GENERAL_NOTIFICATION_SUCCESS";
export const CREATE_GENERAL_NOTIFICATION_FAILURE =
  "CREATE_GENERAL_NOTIFICATION_FAILURE";

export const UPDATE_GENERAL_NOTIFICATION = "UPDATE_GENERAL_NOTIFICATION";
export const UPDATE_GENERAL_NOTIFICATION_SUCCESS =
  "UPDATE_GENERAL_NOTIFICATION_SUCCESS";
export const UPDATE_GENERAL_NOTIFICATION_FAILURE =
  "UPDATE_GENERAL_NOTIFICATION_FAILURE";

export const DELETE_GENERAL_NOTIFICATION = "DELETE_GENERAL_NOTIFICATION";
export const DELETE_GENERAL_NOTIFICATION_SUCCESS =
  "DELETE_GENERAL_NOTIFICATION_SUCCESS";
export const DELETE_GENERAL_NOTIFICATION_FAILURE =
  "DELETE_GENERAL_NOTIFICATION_FAILURE";

export const GET_RELEASE_NOTES_NOTIFICATIONS =
  "GET_RELEASE_NOTES_NOTIFICATIONS";
export const GET_RELEASE_NOTES_NOTIFICATIONS_SUCCESS =
  "GET_RELEASE_NOTES_NOTIFICATIONS_SUCCESS";
export const GET_RELEASE_NOTES_NOTIFICATIONS_FAILURE =
  "GET_RELEASE_NOTES_NOTIFICATIONS_FAILURE";

export const CREATE_RELEASE_NOTES_NOTIFICATION =
  "CREATE_RELEASE_NOTES_NOTIFICATION";
export const CREATE_RELEASE_NOTES_NOTIFICATION_SUCCESS =
  "CREATE_RELEASE_NOTES_NOTIFICATION_SUCCESS";
export const UPDATE_RELEASE_NOTES_NOTIFICATION =
  "UPDATE_RELEASE_NOTES_NOTIFICATION";
export const UPDATE_RELEASE_NOTES_NOTIFICATION_SUCCESS =
  "UPDATE_RELEASE_NOTES_NOTIFICATION_SUCCESS";
export const UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE =
  "UPDATE_RELEASE_NOTES_NOTIFICATION_FAILURE";

export const DELETE_RELEASE_NOTES_NOTIFICATION =
  "DELETE_RELEASE_NOTES_NOTIFICATION";
export const DELETE_RELEASE_NOTES_NOTIFICATION_SUCCESS =
  "DELETE_RELEASE_NOTES_NOTIFICATION_SUCCESS";
export const DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE =
  "DELETE_RELEASE_NOTES_NOTIFICATION_FAILURE";

export const GET_ALL_ACTIVE_NOTIFICATIONS = "GET_ALL_ACTIVE_NOTIFICATIONS";
export const GET_ALL_ACTIVE_NOTIFICATIONS_SUCCESS =
  "GET_ALL_ACTIVE_NOTIFICATIONS_SUCCESS";
export const GET_ALL_ACTIVE_NOTIFICATIONS_FAILURE =
  "GET_ALL_ACTIVE_NOTIFICATIONS_FAILURE";

export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAILURE = "READ_NOTIFICATION_FAILURE";

export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const CLOSE_NOTIFICATION_SUCCESS = "CLOSE_NOTIFICATION_SUCCESS";
export const CLOSE_NOTIFICATION_FAILURE = "CLOSE_NOTIFICATION_FAILURE";

export const GET_SURVEY_NOTIFICATIONS = "GET_SURVEY_NOTIFICATIONS";
export const GET_SURVEY_NOTIFICATIONS_SUCCESS =
  "GET_SURVEY_NOTIFICATIONS_SUCCESS";
export const CREATE_SURVEY_NOTIFICATION = "CREATE_SURVEY_NOTIFICATION";
export const CREATE_SURVEY_NOTIFICATION_SUCCESS =
  "CREATE_SURVEY_NOTIFICATION_SUCCESS";
export const UPDATE_SURVEY_NOTIFICATION = "UPDATE_SURVEY_NOTIFICATION";
export const UPDATE_SURVEY_NOTIFICATION_SUCCESS =
  "UPDATE_SURVEY_NOTIFICATION_SUCCESS";
export const UPDATE_SURVEY_NOTIFICATION_FAILURE =
  "UPDATE_SURVEY_NOTIFICATION_FAILURE";

export const DELETE_SURVEY_NOTIFICATION = "DELETE_SURVEY_NOTIFICATION";
export const DELETE_SURVEY_NOTIFICATION_SUCCESS =
  "DELETE_SURVEY_NOTIFICATION_SUCCESS";
export const DELETE_SURVEY_NOTIFICATION_FAILURE =
  "DELETE_SURVEY_NOTIFICATION_FAILURE";

export const GET_ADHOC_NEWS_NOTIFICATIONS = "GET_ADHOC_NEWS_NOTIFICATIONS";
export const GET_ADHOC_NEWS_NOTIFICATIONS_SUCCESS =
  "GET_ADHOC_NEWS_NOTIFICATIONS_SUCCESS";
export const CREATE_NOTIFICATION_ACTION = "CREATE_NOTIFICATION_ACTION";
export const CREATE_ADHOC_NEWS_NOTIFICATION_SUCCESS =
  "CREATE_ADHOC_NEWS_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_FAILURE =
  "CREATE_NOTIFICATION_FAILURE";

export const UPDATE_ADHOC_NEWS_NOTIFICATION = "UPDATE_ADHOC_NEWS_NOTIFICATION";
export const UPDATE_ADHOC_NEWS_NOTIFICATION_SUCCESS =
  "UPDATE_ADHOC_NEWS_NOTIFICATION_SUCCESS";
export const UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE =
  "UPDATE_ADHOC_NEWS_NOTIFICATION_FAILURE";

export const DELETE_ADHOC_NEWS_NOTIFICATION = "DELETE_ADHOC_NEWS_NOTIFICATION";
export const DELETE_ADHOC_NEWS_NOTIFICATION_SUCCESS =
  "DELETE_ADHOC_NEWS_NOTIFICATION_SUCCESS";
export const DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE =
  "DELETE_ADHOC_NEWS_NOTIFICATION_FAILURE";

/** portal communication feature **/
// admin survey
export const GET_ADMIN_SURVEY = "GET_ADMIN_SURVEY_REQUEST";
export const GET_ADMIN_SURVEY_SUCCESS = "GET_ADMIN_SURVEY_SUCCESS";
export const GET_ADMIN_SURVEY_FAILURE = "GET_ADMIN_SURVEY_FAILURE";

export const DELETE_SURVEY_CONFIRM = "DELETE_SURVEY_CONFIRM_REQUEST";
export const DELETE_SURVEY_CONFIRM_SUCCESS = "DELETE_SURVEY_CONFIRM_SUCCESS";
export const DELETE_SURVEY_CONFIRM_FAILURE = "DELETE_SURVEY_CONFIRM_FAILURE";

export const ADD_ADMIN_SURVEY = "ADD_ADMIN_SURVEY_REQUEST";
export const ADD_ADMIN_SURVEY_SUCCESS = "ADD_ADMIN_SURVEY_SUCCESS";
export const ADD_ADMIN_SURVEY_FAILURE = "ADD_ADMIN_SURVEY_FAILURE";

export const UPDATE_ADMIN_SURVEY = "UPDATE_ADMIN_SURVEY_REQUEST";
export const UPDATE_ADMIN_SURVEY_SUCCESS = "UPDATE_ADMIN_SURVEY_SUCCESS";
export const UPDATE_ADMIN_SURVEY_FAILURE = "UPDATE_ADMIN_SURVEY_FAILURE";
export const GET_SURVEY_DETAILS = "GET_SURVEY_DETAILS";
export const GET_SURVEY_DETAILS_SUCCESS = "GET_SURVEY_DETAILS_SUCCESS";
export const GET_SURVEY_DETAILS_FAILURE = "GET_SURVEY_DETAILS_FAILURE";
export const GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS =
  "GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS_REQUEST";
export const GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS_SUCCESS =
  "GET_ADMIN_SURVEY_DROPDOWN_LIST_ITEMS_SUCCESS";

export const DELAY_SURVEY = "DELAY_SURVEY";
export const DELAY_SURVEY_SUCCESS = "DELAY_SURVEY_SUCCESS";

export const SET_SHOW_EXPIRATION_MODEL = "SET_SHOW_EXPIRATION_MODEL";
export const SHOW_CHANGE_EMAIL_POPUP="SHOW_CHANGE_EMAIL_POPUP";
/** portal communication feature **/
/**Session items */
export const STORE_URL_ERRORCODES="STORE_URL_ERRORCODES";
export const CLEAR_URL_ERRORCODES="CLEAR_URL_ERRORCODES";

export const GET_SERVER_TIME = "GET_SERVER_TIME";
export const GET_SERVER_TIME_SUCCESS = "GET_SERVER_TIME_SUCCESS";
export const GET_SERVER_TIME_FAILURE = "GET_SERVER_TIME_FAILURE";

export const GET_WORKFLOW_TOOLTIPS = "GET_WORKFLOW_TOOLTIPS";
export const GET_WORKFLOW_TOOLTIPS_SUCCESS = "GET_WORKFLOW_TOOLTIPS_SUCCESS";
export const GET_WORKFLOW_TOOLTIPS_FAILURE = "GET_WORKFLOW_TOOLTIPS_FAILURE";

export const GET_LOOK_UP_DATA_REQUEST = "GET_LOOK_UP_DATA_REQUEST";
export const GET_LOOK_UP_DATA_SUCCESS = "GET_LOOK_UP_DATA_SUCCESS";
export const GET_LOOK_UP_DATA_FAILURE = "GET_LOOK_UP_DATA_FAILURE";

// help & support
export const GET_HELP_AND_SUPPORT = "GET_HELP_AND_SUPPORT_REQUEST";
export const GET_HELP_AND_SUPPORT_SUCCESS = "GET_HELP_AND_SUPPORT_SUCCESS";
export const GET_HELP_AND_SUPPORT_FAILURE = "GET_HELP_AND_SUPPORT_FAILURE";

export const GET_USER_PHOTO = "GET_USER_PHOTO_REQUEST";
export const GET_USER_PHOTO_SUCCESS = "GET_USER_PHOTO_SUCCESS";
export const GET_USER_PHOTO_FAILURE = "GET_USER_PHOTO_FAILURE";

export const GET_SELECTED_APPS = "GET_SELECTED_APPS";
export const UPDATE_SELECTED_APPS = "UPDATE_SELECTED_APPS";
export const FLUSH_SELECTED_APPS = "FLUSH_SELECTED_APPS";

export const ADD_PROJECT_DOCUMENT_REQUEST = "ADD_PROJECT_DOCUMENT_REQUEST";
export const ADD_PROJECT_DOCUMENT_SUCCESS = "ADD_PROJECT_DOCUMENT_SUCCESS";
export const ADD_PROJECT_DOCUMENT_FAILURE = "ADD_PROJECT_DOCUMENT_FAILURE";

export const DELETE_PROJECT_DOCUMENT_REQUEST = "DELETE_PROJECT_DOCUMENT_REQUEST";
export const DELETE_PROJECT_DOCUMENT_SUCCESS = "DELETE_PROJECT_DOCUMENT_SUCCESS";
export const DELETE_PROJECT_DOCUMENT_FAILURE = "DELETE_PROJECT_DOCUMENT_FAILURE";