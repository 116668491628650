import React, { Component, Suspense, Fragment } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { Redirect, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";
import { AppLoader, AppModal } from "./theme";
import CreateNewProject from "./containers/createNewProject/createNewProject";
import PrivacyPolicy from "./containers/privacyPolicy/privacyPolicy";
import ProjectDetails from "./containers/projectDetails/projectDetails";
import ManageUsers from "./containers/manageUsers/manageUsers";
import Home from "./containers/home/home";
import AdminProjects from "./containers/adminProjects/adminProjects";
import AdminApps from "./containers/adminApps/adminApps";
import AdminProjectTypes from "./containers/adminProjectTypes/adminProjectTypes";
import AdminReports from "./containers/adminReports/adminReports";
import AdminGeneral from "./containers/adminGeneral/adminGeneral";
import Header from "./components/header/header";
import HeaderAdmin from "./components/headerAdmin/headerAdmin";
import HeaderAdminMenu from "./components/headerAdminMenu/headerAdminMenu";
import Footer from "./components/footer/footer";
import FooterAdmin from "./components/footerAdmin/footerAdmin";
import NotAuthorized from "./containers/notAuthorized/notAuthorized";
import projectWorkflow from "./containers/projectWorkflow/projectWorkflow";
import projectUsageReports from "./containers/projectUsageReports/projectUsageReports";

import { getLookupData } from "./store/actions/meta-actions";
import {
  appErrorHandlerRequest,
  flushAppErrorNotification,
  GET_ALL_ACTIVE_NOTIFICATIONS_ACTION,
  getMyAccessRight,
  getUserPanns,
  storeUrlParams,
  clearUrlParams,
} from "./store/actions/me-actions";
import { IAccessRight, IPansPolicy, IUser } from "./models/user";
import AppNotification from "./components/appNotification/appNotification";
import { INotification } from "./models/INotification";
import {
  hideActionToast,
  showActionToast,
} from "./store/actions/notification-actions";
import { hidePreviewModal } from "./store/actions/preview-video-actions";
import ReactPlayer from "react-player";
import {
  ERROR_URL,
  EProjectStatuses,
  PAANS_RESET_ERROR_CODE,
  POLICY_ERROR_CODE,
  POLICY_ERROR_CODE_KEY,
  POLICY_URL,
  PROJECT_RESTRICT_ERROR_CODE_KEY,
  REPLAY_ERROR,
  HomePageLookupTableNames,
} from "./utils/Constants";
import {
  checkIfAppPlatformPolicyError,
  getAdminConsoleAccess,
  getCaseInsensitiveParamValue,
  isPortalAdmin,
  isProjectAdmin,
} from "./utils/helper-utility";
import AdminUsers from "./containers/adminUsers/adminUsers";
import AdminMaintenance from "./containers/adminMaintenance/adminMaintenance";
import {
  IMenuLinks,
  MenuLinks,
  MenuLinksKeys,
} from "./components/headerAdminMenu/menuLinks";
import { ISSPPlatformRole } from "./models/IRoles";
import t from "./localization/en/translation.json";
import AzureApplicationInsightsService from "./utils/appInsights";
import EMGPolicy from "./components/emgPolicy/emgPolicy";
import { EmailChangeModalPopUp } from "./components/emailChangeModal/emailChangeModal";
import "./styles/globalStyles.scss";
import "./App.scss";
import { CrashBoundary } from "./components/CrashBoundary";
import { PORTAL_COMMS } from "./containers/adminGeneral/adminGeneralConstants";
import Survey from "./components/adminPortalCommunications/Survey/survey";
import { IUrlParams } from "./models/urlParamsStorage";
import ErrorPage from "./containers/error/error";
import RouteHandler from "./routeHandler";
import HelpAndSupport from "./components/HelpAndSupport/HelpAndSupport";
import { UserTypes } from "./utils/Constants";
import { AssistantConfiguration } from "./containers/assistantContainer/assistantConfiguration";
import { MaintenanceBanner } from "./components/maintenanceBanner/maintenanceBanner";

const IdleLogoutWarningModalAsync = React.lazy(
  () => import("./IdleLogoutWarningModal")
);

type Props = RouteComponentProps<any> & {
  getLookupData: Function;
  meLoading: boolean;
  getUserPanns: Function;
  paansPolicy: IPansPolicy;
  getMyAccessRight: Function;
  accessRight: IAccessRight;
  notification: INotification;
  hideNotification: Function;
  showActionToast: Function;
  showPreviewVideo: boolean;
  previewUrl: string;
  hidePreviewModal: Function;
  appErrorHandlerRequest: Function;
  flushAppErrorNotification: Function;
  getActiveNotification: Function;
  resetPaans: {
    isSuccess: boolean;
    redirectUrl?: string;
  };
  appErrorFailNotification: {};
  sspRoles: ISSPPlatformRole[];
  currentUser: IUser;
  showMailChangeModalPopUp: boolean;
  urlParams: IUrlParams;
  storeUrlParams: Function;
  clearUrlParams: Function;
};

interface IState {
  menuLinks: IMenuLinks[];
  isAppLoaded: boolean;
  hideBanner: boolean;
}

export class App extends Component<Props, IState> {
  azureAppInsights: AzureApplicationInsightsService;

  constructor(props: Props) {
    super(props);

    this.azureAppInsights = new AzureApplicationInsightsService();
  }

  state = {
    menuLinks: [],
    isAppLoaded: false,
    hideBanner: false,
  };

  componentDidMount() {
    // look for new notifications upon each route change
    const {
      meLoading,
      appErrorHandlerRequest,
      storeUrlParams,
      clearUrlParams,
      notification,
    } = this.props;
    checkIfAppPlatformPolicyError(storeUrlParams, clearUrlParams);
    this.props.history.listen(() => {
      if (notification.status !== REPLAY_ERROR)
        this.props.getActiveNotification();
    });
    const url = new URL(window.location.href);
    const errorCode = getCaseInsensitiveParamValue(
      url.searchParams,
      "errorcode"
    );

    if (meLoading === false) {
      this.loadBaseFeatures();
    }

    if (errorCode || document.referrer.toLowerCase().indexOf("wrike") > -1) {
      appErrorHandlerRequest(errorCode || PAANS_RESET_ERROR_CODE);
      return;
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { meLoading, resetPaans, accessRight, sspRoles } = this.props;
    const { isAppLoaded } = this.state;

    if (prevProps.resetPaans !== resetPaans && resetPaans.redirectUrl) {
      window.location.href = `${resetPaans.redirectUrl || "/"}`;
    }

    if (meLoading === false && prevProps.meLoading === true && !isAppLoaded) {
      this.loadBaseFeatures();
    } else if (
      !accessRight.loading &&
      prevProps.accessRight.loading &&
      !isAppLoaded
    ) {
      this.setState({
        isAppLoaded: true,
      });
    }

    if (
      prevProps.accessRight !== accessRight ||
      prevProps.sspRoles !== sspRoles
    ) {
      this.setAdminMenuLinks();
    }

    this.handleRoutes(prevProps);
  }

  loadBaseFeatures = () => {
    const { currentUser } = this.props;
    this.getLookupDataList();
    this.getMyAccessRights();
    if (currentUser && currentUser.id) {
      this.azureAppInsights.updateUser(currentUser);
      this.azureAppInsights.enableApplicationInsights();
      (window as any).appInsights = this.azureAppInsights;
    }
    this.redirectToPolicyPage();
    this.getUserPaansStatus();
  };

  handleRoutes = (prevProps: Props) => {
    const { notification, history, location, sspRoles } = this.props;
    const { isAppLoaded } = this.state;
    this.checkPaans(prevProps);
    const url = new URL(window.location.href);

    const searchParam = getCaseInsensitiveParamValue(
      url.searchParams,
      POLICY_ERROR_CODE_KEY
    );

    if (
      url.pathname === ERROR_URL &&
      searchParam?.toLowerCase() === PROJECT_RESTRICT_ERROR_CODE_KEY
    ) {
      this.props.showActionToast({
        message: t.permission_denied_error,
        type: "error",
        status: 403,
        redirectURL: "/",
      });
    }
    //Remove errorCode
    storeUrlParams({
      errorCode: POLICY_ERROR_CODE_KEY,
      value: "",
    });
    if (notification.message && !prevProps.notification.message) {
      const notAuthorizedRoute = "/not-authorized";
      if (
        notification.code?.toLowerCase() === EProjectStatuses.PROJECTCLOSED &&
        location.pathname.includes("/project/")
      ) {
        setTimeout(() => {
          history.push("/");
          this.closeNotification();
        }, 3000);
      } else {
        setTimeout(this.closeNotification, 5000);
      }
      if (
        notification.redirectToUnauth &&
        location.pathname !== notAuthorizedRoute
      ) {
        history.push(notAuthorizedRoute);
      } else if (
        location.pathname !== notAuthorizedRoute &&
        location.pathname !== "/" &&
        location.pathname !== "/#" &&
        notification.status === 403
      ) {
        history.push("/");
      } else if (notification.status === 400) {
        history.push(location.pathname);
      }
    }
    const adminConsoleAccess =
      isAppLoaded && sspRoles.length > 0 ? this.getAdminConsoleAccess() : null;
    if (
      isAppLoaded &&
      location.pathname.includes("admin/maintenance") &&
      !(adminConsoleAccess && adminConsoleAccess.portalAdmin)
    ) {
      this.props.showActionToast({
        message: t.permission_denied_error,
        type: "error",
        status: 403,
        redirectToUnauth: "/",
      });
    }
  };

  checkUserIsProjectAdmin = (projectId: string | undefined) => {
    const { sspRoles, accessRight } = this.props;
    return isProjectAdmin(projectId || "", accessRight, sspRoles);
  };

  checkPaans = (prevProps: Props) => {
    const { paansPolicy, location, showMailChangeModalPopUp } = this.props;
    if (!showMailChangeModalPopUp) {
      if (
        (paansPolicy.loaded === true &&
          prevProps.paansPolicy.loaded === false) ||
        (location !== prevProps.location &&
          location.pathname === "/" &&
          (prevProps.location.pathname === "/policy-error" ||
            prevProps.location.pathname === POLICY_URL))
      ) {
        this.checkPannsPolicy();
      }
    }
  };
  redirectToPolicyPage = () => {
    const { location, history, urlParams } = this.props;
    const url = new URL(window.location.href);
    if (
      location.pathname === ERROR_URL &&
      !getCaseInsensitiveParamValue(url.searchParams, "errorCode")
    ) {
      const errorParams = urlParams;
      if (
        errorParams.errorCode?.toLowerCase() === PROJECT_RESTRICT_ERROR_CODE_KEY
      ) {
        history.push(`${ERROR_URL}?errorCode=${errorParams.errorCode}`);
      }
    } else if (
      (location.pathname !== POLICY_URL ||
        (location.pathname === POLICY_URL &&
          !getCaseInsensitiveParamValue(url.searchParams, "errorCode"))) &&
      !location.pathname.includes("/admin")
    ) {
      const policyErrorParams = urlParams;
      if (
        policyErrorParams.policyErrorCode?.toLowerCase() === POLICY_ERROR_CODE
      ) {
        history.push(
          `${POLICY_URL}?errorCode=${
            policyErrorParams.policyErrorCode
          }&redirectURI=${btoa(policyErrorParams.policyRedirectURL || "")}`
        );
      }
    }
  };

  getUserPaansStatus = () => {
    const { getUserPanns } = this.props;
    getUserPanns();
  };

  getLookupDataList = () => {
    const { getLookupData } = this.props;
    getLookupData(HomePageLookupTableNames);
  };

  checkPannsPolicy = () => {
    const { paansPolicy, history, location, notification } = this.props;
    if (notification.status !== REPLAY_ERROR) {
      if (
        paansPolicy &&
        !paansPolicy.hasAcceptedAll &&
        location.pathname !== POLICY_URL
      ) {
        history.push(POLICY_URL);
      }
    } else {
      history.push({
        pathname: "error",
        state: {
          errorHeader: t.replay_error_header,
          errorBody: t.replay_error_body,
        },
      });
    }
  };

  getMyAccessRights = () => {
    const { getMyAccessRight, accessRight } = this.props;
    if (!accessRight.loading) {
      getMyAccessRight();
    }
  };

  closeNotification = () => {
    const { hideNotification, notification, location, history } = this.props;
    hideNotification();
    if (
      notification.redirectURL &&
      notification.redirectURL !== location.pathname
    ) {
      history.push(notification.redirectURL);
    }
  };

  toggleDemoModal = () => {
    const { hidePreviewModal } = this.props;
    hidePreviewModal();
  };

  isAdminConsole = () => {
    const { location } = this.props;
    return location.pathname.includes("/admin/");
  };

  setAdminMenuLinks = () => {
    const { accessRight, sspRoles } = this.props;
    const menuLinks = MenuLinks;
    if (accessRight.portalRoles && sspRoles) {
      const portalRole =
        (accessRight?.portalRoles &&
          accessRight.portalRoles.length > 0 &&
          accessRight.portalRoles[0]) ||
        "";
      const portalAdmin = isPortalAdmin(portalRole, sspRoles);
      if (portalAdmin) {
        _.find(menuLinks, { key: MenuLinksKeys.MAINTENANCE }).isActive = true;
      }
      this.setState({ menuLinks });
    }
  };

  getAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  getHeaderHeight = (isHideBanner) => {
    if (this.isAdminConsole()) {
      return;
    } else {
      if(isHideBanner) {
        this.setState({hideBanner: true});
      }
      const { hideBanner } = this.state;
      const headerHeight = document.querySelector(".header")?.clientHeight || 0;
      const bannerHeight =
        document.querySelector(".app-maintenance-banner")?.clientHeight || 0;

      return hideBanner ? headerHeight : headerHeight + bannerHeight;
    }
  };

  render() {
    const {
      paansPolicy,
      notification,
      showPreviewVideo,
      previewUrl,
      appErrorFailNotification,
      flushAppErrorNotification,
      meLoading,
      sspRoles,
      showMailChangeModalPopUp,
      currentUser,
    } = this.props;
    const { menuLinks, isAppLoaded } = this.state;
    const isAdminConsole = this.isAdminConsole();

    const adminConsoleAccess =
      isAppLoaded && sspRoles.length > 0 ? this.getAdminConsoleAccess() : null;
    const canAccessAdminConsole =
      adminConsoleAccess &&
      (adminConsoleAccess.portalAdmin || adminConsoleAccess.portalAdminConsole);
    const canAdminConsoleLoad =
      isAdminConsole &&
      isAppLoaded &&
      adminConsoleAccess &&
      (adminConsoleAccess.portalAdmin || adminConsoleAccess.portalAdminConsole);
    const canRoutesBeLoaded =
      paansPolicy.hasAcceptedAll && isAppLoaded && adminConsoleAccess;

    return (
      <CrashBoundary>
        {showMailChangeModalPopUp && <EmailChangeModalPopUp />}

        <Fragment>
          {canAdminConsoleLoad ? <HeaderAdmin /> : <Header />}
          {isAdminConsole && !meLoading && (
            <HeaderAdminMenu menuLinks={menuLinks} />
          )}
          {!isAdminConsole && <MaintenanceBanner getHeaderHeight={this.getHeaderHeight}/>}
          <div
            style={{ marginTop: this.getHeaderHeight() }}
            className={
              "full-height-container" +
              (isAdminConsole
                ? " admin-console-page-container"
                : " home-page-container")
            }
          >
            <Route path="/not-authorized" component={NotAuthorized} />
            <Route path="/error" component={ErrorPage}></Route>
            <Route path={POLICY_URL} component={PrivacyPolicy} />
            <Route path="/policy-error" component={NotAuthorized} />

            {canRoutesBeLoaded && (
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/create" component={CreateNewProject} />
                <Route path="/not-authorized" component={NotAuthorized} />
                <Route exact path="/project/:id" component={ProjectDetails} />
                <Route
                  exact
                  path="/project/:id/handler/:encodedParams"
                  component={ProjectDetails}
                />
                <Route
                  exact
                  path="/project/:id/manage-users"
                  component={ManageUsers}
                />
                <Route
                  exact
                  path="/project/:id/manage-assistant"
                  component={AssistantConfiguration}
                />
                <Route
                  exact
                  path="/project/:id/project-details"
                  render={(props) => (
                    <RouteHandler
                      {...props}
                      component={CreateNewProject}
                      condition={this.checkUserIsProjectAdmin}
                    />
                  )}
                />
                <Route
                  path="/project/:id/project-details/:encodedParams"
                  render={(props) => (
                    <RouteHandler
                      {...props}
                      component={CreateNewProject}
                      condition={this.checkUserIsProjectAdmin}
                    />
                  )}
                />
                <Route
                  path="/project/:id/manage-workflows/workflows/:tabKey"
                  component={projectWorkflow}
                  key={this.props.location.pathname}
                />
                <Route
                  path="/project/:id/manage-workflows/email-communications/:tabKey"
                  component={projectWorkflow}
                  key={this.props.location.pathname}
                />
                <Route
                  path="/project/:projectId/usage-reports/:reportId"
                  component={projectUsageReports}
                  key={this.props.location.pathname}
                />
                {currentUser?.userType?.toLowerCase() ===
                  UserTypes.Internal.toLowerCase() && (
                  <Route
                    exact
                    path="/help-support"
                    component={HelpAndSupport}
                  />
                )}
                {canAccessAdminConsole && (
                  <>
                    <Route
                      exact
                      path="/admin/projects"
                      component={AdminProjects}
                    />
                    <Route path="/admin/apps" component={AdminApps} />
                    <Route
                      path="/admin/project-types"
                      component={AdminProjectTypes}
                    />
                    <Route path="/admin/reports" component={AdminReports} />
                    <Route path="/admin/users" component={AdminUsers} />
                    <Route
                      path="/admin/projects/:id/email-notifications/:tabKey"
                      render={(props) => (
                        <Redirect
                          to={`/project/${props.match.params.id}/manage-workflows/email-communications/${props.match.params.tabKey}`}
                        />
                      )}
                    />
                    <Route
                      path="/admin/projects/:id/workflows/:tabKey"
                      render={(props) => (
                        <Redirect
                          to={`/project/${props.match.params.id}/manage-workflows/workflows/${props.match.params.tabKey}`}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/administration"
                      component={AdminGeneral}
                    />
                    <Route
                      path="/admin/administration/general/:tabKey"
                      component={AdminGeneral}
                    />
                    <Route
                      path="/admin/administration/email-notifications/:tabKey"
                      render={(props) => (
                        <Redirect
                          to={`/admin/administration/email-communications/${props.match.params.tabKey}`}
                        />
                      )}
                    />
                    <Route
                      path="/admin/administration/email-communications/:tabKey"
                      component={AdminGeneral}
                    />
                    <Route
                      path="/admin/administration/workflows/:tabKey"
                      component={AdminGeneral}
                    />
                    <Route
                      path="/admin/administration/surveys"
                      component={AdminGeneral}
                    />
                    <Route
                      path={`/admin/administration/${PORTAL_COMMS}/:tabKey`}
                      component={AdminGeneral}
                    />
                    {adminConsoleAccess && adminConsoleAccess.portalAdmin && (
                      <Route
                        path="/admin/maintenance"
                        component={AdminMaintenance}
                      />
                    )}
                  </>
                )}
                <Redirect to="/" />
              </Switch>
            )}
            {notification.message && (
              <AppNotification
                message={notification.message}
                onCloseNotifcation={this.closeNotification}
                variant={notification.type || "success"}
                useHtml={notification.useHtml}
              />
            )}
            {!!Object.keys(appErrorFailNotification).length && (
              <div className="url-error-notification">
                <AppNotification
                  message={t.generic_error_message}
                  onCloseNotifcation={() => flushAppErrorNotification()}
                  variant={"error"}
                  useHtml={notification.useHtml}
                />
              </div>
            )}
          </div>
          {canAdminConsoleLoad ? <FooterAdmin /> : <Footer />}
          <AppLoader />
          <div className="demo-video-dialog">
            <AppModal
              size="md"
              modalClass="app-preview-modal"
              showModal={showPreviewVideo}
              onModalClose={() => this.toggleDemoModal()}
            >
              <div className="modal-content">
                <div>
                  <ReactPlayer
                    url={previewUrl}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="503px"
                  />
                </div>
              </div>
            </AppModal>
          </div>
          <EMGPolicy />
          <Survey />
        </Fragment>
        <Suspense fallback={<></>}>
          <IdleLogoutWarningModalAsync />
        </Suspense>
      </CrashBoundary>
    );
  }
}

const mapStoreToProps = ({
  meReducer,
  notification,
  previewVideo: { showPreviewVideo, previewUrl },
  meta,
  urlParamStorageReducer,
}) => {
  return {
    paansPolicy: meReducer.paansPolicy,
    currentUser: meReducer.currentUser,
    meLoading: meReducer.loading,
    accessRight: meReducer.accessRight,
    resetPaans: meReducer.resetPaans,
    appErrorFailNotification: meReducer.appErrorFailNotification,
    notification,
    showPreviewVideo,
    previewUrl,
    sspRoles: meta.userRoles,
    showMailChangeModalPopUp: meReducer.showMailChangeModalPopUp,
    urlParams: urlParamStorageReducer.urlParams,
  };
};

const mapDispatchToProps = {
  getUserPanns,
  getMyAccessRight,
  hideNotification: hideActionToast,
  showActionToast: showActionToast,
  hidePreviewModal,
  appErrorHandlerRequest,
  flushAppErrorNotification,
  getActiveNotification: GET_ALL_ACTIVE_NOTIFICATIONS_ACTION,
  storeUrlParams,
  clearUrlParams,
  getLookupData,
};

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(App));
