import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import * as _ from "lodash";
import List from "../../components/editableGrid/list/list";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import { getAdminAppsRequest, getAdminAppsVideo, deleteAdminAppsVideo, deleteAdminAppAction, flushAdminAppAction, searchAdminAppsAction, getAdminAppsThumbnail, deleteAdminAppsThumbnail } from "../../store/actions/admin-projects-actions";
import { showPreviewModal } from "../../store/actions/preview-video-actions";
import { updatefilterState } from "../../store/actions/list-actions";
import { columnConfig, appOwnersFormConfig } from "./columnConfig";
import t from "../../localization/en/translation.json";
import { copy, play, trash, refresh, search } from "../../theme/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MoreMenuOption } from "../../components/moreMenu/moreMenuOptions";
import MoreMenu from "../../components/moreMenu/moreMenu";
import { MAX_DESC_LENGTH, ACTION_NAME, MIN_APP_NAME_LENGTH, DEFAULT_SORT } from "./adminAppConstants";
import AppNotification from "../../components/appNotification/appNotification";
import AdminAppsModal from "../../components/adminAppsModal/adminAppsModal";
import { getProjectRegions } from "../../store/actions/data-locations-actions";
import { getAppCategories, getAppPlatforms, getBundles, getSubServiceLines } from "../../store/actions/meta-actions";
import { BundleClass } from "../../components/bundleCard/models/bundleClass";
import { addProjectAppsAction, updateProjectAppsAction } from "../../store/actions/project-apps-actions";
import { searchAdminUserGrid } from "../../store/actions/admin-users-actions";
import { AddAppClass } from "../../components/appCard/models/appClass";
import { getDefaultPaginationModel, getPaginationModel } from "../../models/IPagination";
import { getAdminConsoleAccess, validateFormInputForSpecialChar } from "../../utils/helper-utility";
import { AppButton, AppModal, AppTooltip, AppIcon } from "../../theme";
import "./adminApps.scss";
import { CrashBoundary } from "../../components/CrashBoundary";
import { AdminAppsProps } from "./adminAppsModel"

type IProps = RouteComponentProps<any> & AdminAppsProps;

class AdminApps extends Component<IProps> {
  gridApi;
  typeahead;
  notificationVariant = "";
  notificationMessage = "";
  notificationEditVariant = "";
  notificationEditMessage = "";
  notificationAddVariant = "";
  notificationAddMessage = "";
  appOwners = appOwnersFormConfig;
  noValidateField = [
    "typeDemoSiteURL",
    "typeAppImageURL",
    "typeAutoPublish",
    "typeAppOwnersCcEmail",
    "typeAppBundle",
    "typeIsLimitedAvailable",
    "typeAppHotlink",
  ];
  formState = this.setFormAdminApps();
  state = {
    showLinkCopiedNotifiacation: false,
    paginationPayload: getPaginationModel({
      ...getDefaultPaginationModel(),
      sortDirection: DEFAULT_SORT.sort,
      sortColumn: DEFAULT_SORT.colId,
      totalRecords: 0,
      filters: [],
    }),
    showAddOrEditModal: false,
    isEdit: false,
    formState: this.formState,
    isFormValid: false,
    showEditNotification: false,
    showAddNotification: false,
    filePathLink: "",
    thumbnailPathLink: "",
    showDeleteModal: false,
    selectedApp: {},
    showNotification: false,
    acceptedFilesDrag: false,
    softDeleteVideo: false,
    softDeleteThumbnail: false,
    demoPathLink: "",
    imagePathLink: "",
    searchQuery: [],
    showThumbnailPreview: false
  };

  componentDidMount() {
    const {
      appCategories,
      getAppCategories,
      appPlatforms,
      getAppPlatforms,
      projectRegions,
      getProjectRegions,
      bundles,
      getBundleCards,
      subServiceLines,
      getSubServiceLines
    } = this.props;
    !appCategories?.length && getAppCategories();
    !bundles?.length && getBundleCards();
    !appPlatforms?.length && getAppPlatforms();
    !projectRegions?.length && getProjectRegions();
    !subServiceLines?.length && getSubServiceLines();

    this.getAdminApps();
    this.updateFilterState();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showLinkCopiedNotifiacation, paginationPayload, formState } =
      this.state;
    const { filePath, projectAppsAdd, deleteFilePath, thumbnailPath, deleteThumbnailPath } = this.props;

    if (
      prevState.showLinkCopiedNotifiacation !== showLinkCopiedNotifiacation &&
      showLinkCopiedNotifiacation
    ) {
      setTimeout(
        () => this.setState({ showLinkCopiedNotifiacation: false }),
        3000
      );
    }

    if (prevState.paginationPayload !== paginationPayload) {
      this.getAdminApps();
      this.updateFilterState();
    }

    if (prevProps.filePath !== filePath && filePath) {
      formState.typeDemoSiteURL.fieldVal = filePath;
      this.setState({
        formState: formState,
        filePathLink: filePath,
        isFormValid: this.checkDirtyFields(),
        acceptedFilesDrag: true,
      });
    }

    if (prevProps.thumbnailPath !== thumbnailPath && thumbnailPath) {
      formState.typeAppImageURL.fieldVal = thumbnailPath;
      this.setState({
        formState: formState,
        thumbnailPathLink: thumbnailPath,
        isFormValid: this.checkDirtyFields(),
        acceptedFilesDrag: true,
      });
    }

    if (prevProps.deleteFilePath !== deleteFilePath && deleteFilePath) {
      formState.typeDemoSiteURL.fieldVal = "";
      this.setState({
        formState: formState,
        filePathLink: "",
        isFormValid: this.checkDirtyFields(),
        acceptedFilesDrag: false,
      });
    }

    if (prevProps.deleteThumbnailPath !== deleteThumbnailPath && deleteThumbnailPath) {
      formState.typeAppImageURL.fieldVal = "";
      this.setState({
        formState: formState,
        thumbnailPathLink: "",
        isFormValid: this.checkDirtyFields(),
        acceptedFilesDrag: false,
      });
    }

    if (
      projectAppsAdd !== prevProps.projectAppsAdd &&
      projectAppsAdd?.success === true
    ) {
      this.manageAddNotification(true);
      this.getAdminApps();
    } else if (
      projectAppsAdd !== prevProps.projectAppsAdd &&
      projectAppsAdd?.success === false
    ) {
      this.manageAddNotification(false);
    }

    this.manageNotification(prevProps);
  }

  manageNotification = (prevProps) => {
    const { searchQuery } = this.state;
    const { projectAppsUpdate, deleteApp } = this.props;

    if (
      projectAppsUpdate !== prevProps.projectAppsUpdate &&
      projectAppsUpdate?.success === true
    ) {
      this.manageEditNotification(true);
      this.getAdminApps();
      if (searchQuery.length) {
        this.clearSearch();
        this.setState({ searchQuery: [] });
      }
    } else if (
      projectAppsUpdate !== prevProps.projectAppsUpdate &&
      projectAppsUpdate?.success === false
    ) {
      this.manageEditNotification(false);
    }

    if (deleteApp?.success) {
      this.manageDeleteNotification(true);
      this.getAdminApps();
      if (searchQuery.length) {
        this.clearSearch();
        this.setState({ searchQuery: [] });
      }
    } else if (deleteApp?.error) {
      this.manageDeleteNotification(false);
    }
  };

  manageEditNotification = (success: boolean) => {
    this.notificationEditVariant = success ? "success" : "error";
    this.notificationEditMessage = success
      ? t.admin_portal_edit_project_apps_success
      : t.admin_portal_edit_project_apps_error;
    this.setState({ showEditNotification: true });
    setTimeout(() => {
      if (this.state.showEditNotification) {
        this.setState({ showEditNotification: false });
      }
    }, 3000);
  };

  manageAddNotification = (success: boolean) => {
    this.notificationAddVariant = success ? "success" : "error";
    this.notificationAddMessage = success
      ? t.admin_portal_add_project_apps_success
      : t.admin_portal_add_project_apps_error;
    this.setState({ showAddNotification: true });
    setTimeout(() => {
      if (this.state.showAddNotification) {
        this.setState({ showAddNotification: false });
      }
    }, 3000);
  };

  getAdminApps = () => {
    const { paginationPayload } = this.state;
    this.props.getAdminAppsRequest(paginationPayload);
  };

  onGridReady = ({ api }) => {
    this.gridApi = api;
  };

  handleVideoPlay = (url) => {
    this.props.showPreviewModal(url);
  };

  handlePageNumberChange = (pageNumber) => {
    this.handlePagination({ pageNumber });
  };

  handlePageSizeChange = (pageSize) => {
    this.handlePagination({ pageSize, pageNumber: 1 });
  };

  handlePagination = (paginationParams) => {
    const { paginationPayload } = this.state;
    const updatedPaginationPayload = {
      ...paginationPayload,
      ...paginationParams,
    };

    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  renderAppCategories = ({ value, data }) => {
    if (!value || !data) {
      return null;
    }

    return (
      <div>
        <span
          style={{ backgroundColor: data.categoryColorCode }}
          className="category-color"
        ></span>
        <span className="category-title">{value}</span>
      </div>
    );
  };

  handleMoreMenuOptionClick = (actionName: string) => {
    if (actionName === ACTION_NAME.DELETE) {
      const rows = this.gridApi.getSelectedRows();
      this.setState({ showDeleteModal: true, selectedApp: rows[0] });
    } else if (actionName === ACTION_NAME.UPDATE) {
      const rows = this.gridApi.getSelectedRows();
      this.setState({ selectedApp: rows[0] });
      this.onEditClick(rows[0]);
    }
  };

  renderMoreMenu = () => {
    const options: MoreMenuOption[] = [
      {
        id: "123",
        title: t.update,
        icon: refresh,
        actionName: ACTION_NAME.UPDATE,
      },
      {
        id: "124",
        title: t.delete,
        icon: trash,
        actionName: ACTION_NAME.DELETE,
      },
    ];

    return (
      <MoreMenu
        controlId="more-menu-apps"
        optionClickFn={this.handleMoreMenuOptionClick}
        options={options}
        vertical
        leftPlacement
      />
    );
  };

  renderDescriptionRows = ({ value }) => {
    return (
      <AppTooltip title={value} isWhite>
        <span>
          {value.length > MAX_DESC_LENGTH
            ? `${value.substr(0, MAX_DESC_LENGTH)}...`
            : value}
        </span>
      </AppTooltip>
    );
  };

  renderOwners = ({ value: owners }) => {
    const { projectRegions } = this.props;

    if (!owners || !owners.length) {
      return null;
    }

    const ownerEmailIds = owners.map((owner) => owner?.email);
    const tooltipMsg = (
      <div>
        {owners.map((owner, key) => {
          const region = projectRegions.find(
            (region) => region.id === owner.regionId
          )?.name;

          return (
            region && (
              <div key={key}>
                {region}: {owner.name}
              </div>
            )
          );
        })}
      </div>
    );

    return (
      <React.Fragment>
        <div className="ag-react-container">
          <a
            className="apps-owner-button"
            href={`mailto:${ownerEmailIds.join(";")}`}
          >
            {owners[0]?.name}
          </a>
          {owners.length > 1 && (
            <AppTooltip
              className="owner-count-tooltip"
              title={tooltipMsg}
              isWhite
            >
              <span className="owner-count">+ {owners.length - 1}</span>
            </AppTooltip>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderAppBundles = ({ value: bundles }) => {
    if (!bundles.length) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="ag-react-container">
          <span>{bundles[0]}</span>
          {bundles.length > 1 && (
            <AppTooltip title={bundles.join(",")} isWhite>
              <span className="bundle-count">+ {bundles.length - 1}</span>
            </AppTooltip>
          )}
        </div>
      </React.Fragment>
    );
  };

  renderPreviewVideoRows = ({ value }) => {
    if (!value?.length) {
      return null;
    }

    return (
      <Fragment>
        <AppButton
          className="apps-play-button btn-link"
          onClick={() => this.props.showPreviewModal(value)}
          variant="text"
        >
          <AppIcon icon={play} />
          {t.play_video_text}
        </AppButton>
        <CopyToClipboard
          text={value}
          onCopy={() => this.setState({ showLinkCopiedNotifiacation: true })}
        >
          <AppButton className="apps-copy-button btn-link" variant="text">
            <AppIcon icon={copy} />
            {t.copy_link_text}
          </AppButton>
        </CopyToClipboard>
      </Fragment>
    );
  };

  renderThumbnailImage = ({ value }) => {
    const { showThumbnailPreview } = this.state;
    if (!value?.length) {
      return null;
    }

    return (
      <Fragment>
        <AppButton
          className="apps-play-button btn-link"
          onClick={() => this.setState({showThumbnailPreview: true})}
          variant="text"
        >
          {t.view_thumbnail_text}
        </AppButton>
        <div className="demo-video-dialog">
          <AppModal
            size="md"
            modalClass="thumbnail-preview-dialog"
            showModal={showThumbnailPreview}
            onModalClose={() => this.setState({showThumbnailPreview: false})}
          >
            <div className="modal-content">
              <div>
                <img src={value} alt="thumbnail-preview" className="thumbnail-preview" />
              </div>
            </div>
          </AppModal>
        </div>
      </Fragment>
    );
  };

  handleAddApps = () => {
    const formState = this.setFormAdminApps();
    this.setState({
      showAddOrEditModal: true,
      isEdit: false,
      formState,
      isFormValid: false,
      thumbnailPathLink: ""
    });
  };

  setFormAdminApps(selectedaap?) {
    const { bundles, subServiceLines } = this.props;
    let dataAppOwner, appBundle = [], appSubServiceLines = [];

    if (selectedaap?.demoSiteURL) {
      this.setState({ filePathLink: selectedaap.demoSiteURL });
    }
    if (selectedaap?.appImageURL) {
      this.setState({ thumbnailPathLink: selectedaap.appImageURL });
    }
    if (selectedaap?.appOwners && selectedaap?.appOwners.length > 0) {
      dataAppOwner = selectedaap?.appOwners.map((data) => ({
        typeAppOwnersRegionId: {
          fieldName: "typeAppOwnersRegionId",
          fieldVal: data.regionId || "",
          isvalid: true,
          charLimit: 200,
          disable: false,
          fieldTranslate: "project_region",
        },
        typeAppOwnersName: {
          fieldName: "typeAppOwnersName",
          fieldVal: data.name || "",
          isvalid: true,
          charLimit: 200,
          disable: false,
          fieldTranslate: "admin_apps_owner_name",
        },
        typeAppOwnersEmail: {
          fieldName: "typeAppOwnersEmail",
          fieldVal: data.email || "",
          isvalid: true,
          charLimit: 200,
          disable: true,
          fieldTranslate: "admin_apps_owner_email",
        },
        typeAppOwnersCcEmail: {
          fieldName: "typeAppOwnersCcEmail",
          fieldVal: data.ccEmail || "",
          isvalid: true,
          charLimit: 200,
          disable: false,
          fieldTranslate: "admin_apps_cc_email",
        },
      }));
    }
    if (selectedaap?.bundlesId) {
      appBundle = selectedaap?.bundlesId.map((bundleId) => {
        return bundles.find((item) => item.id === bundleId)?.name;
      });
    }

    if (selectedaap?.subServiceLines) {
      appSubServiceLines = selectedaap?.subServiceLines.map((id) => {
        return subServiceLines.find((item) => item.id === id)?.name;
      });
    }

    return {
      typeId: {
        fieldName: "typeId",
        fieldVal: selectedaap?.id ? selectedaap.id : "",
        isvalid: true,
        charLimit: 200,
        disable: true,
      },
      typeName: {
        fieldName: "typeName",
        fieldVal: selectedaap?.name ? selectedaap.name : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeKey: {
        fieldName: "typeKey",
        fieldVal: selectedaap?.key ? selectedaap.key : "",
        isvalid: true,
        charLimit: 15,
        disable: false,
      },
      typeDesc: {
        fieldName: "typeDesc",
        fieldVal: selectedaap?.description ? selectedaap.description : "",
        isvalid: true,
        charLimit: 2000,
        disable: false,
      },
      typeAppCategory: {
        fieldName: "typeAppCategory",
        fieldVal: selectedaap?.appCategoryId ? selectedaap.appCategoryId : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeDemoSiteURL: {
        fieldName: "typeDemoSiteURL",
        fieldVal: selectedaap?.demoSiteURL ? selectedaap.demoSiteURL : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAppImageURL: {
        fieldName: "typeAppImageURL",
        fieldVal: selectedaap?.appImageURL ? selectedaap.appImageURL : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAppRelativeURL: {
        fieldName: "typeAppRelativeURL",
        fieldVal: selectedaap?.appRelativeURL ? selectedaap.appRelativeURL : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAppPlatformClassId: {
        fieldName: "typeAppPlatformClassId",
        fieldVal: selectedaap?.appPlatformClassId
          ? selectedaap.appPlatformClassId
          : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAutoPublish: {
        fieldName: "typeAutoPublish",
        fieldVal: selectedaap?.autoPublish
          ? selectedaap.autoPublish?.toString()
          : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAppBundle: {
        fieldName: "typeAppBundle",
        fieldVal: appBundle,
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeExternalAppKey: {
        fieldName: "typeExternalAppKey",
        fieldVal: selectedaap?.externalAppKey ? selectedaap.externalAppKey : "",
        isvalid: true,
        charLimit: 200,
        disable: true,
      },
      typeAppHotlink: {
        fieldName: "typeAppHotlink",
        fieldVal: selectedaap?.appHotlink ? selectedaap.appHotlink : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeAppOwners: dataAppOwner
        ? dataAppOwner
        : [_.cloneDeep(this.appOwners)],
      typeIsLimitedAvailable: {
        fieldName: "typeIsLimitedAvailable",
        fieldVal: selectedaap?.isLimitedAvailable
          ? selectedaap.isLimitedAvailable?.toString()
          : "",
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
      typeSubServiceLines: {
        fieldName: "typeSubServiceLines",
        fieldVal: appSubServiceLines,
        isvalid: true,
        charLimit: 200,
        disable: false,
      },
    };
  }

  handleAddOrEditApps = () => {
    const { isEdit, formState, softDeleteVideo, demoPathLink, softDeleteThumbnail, imagePathLink } = this.state;
    const { addProjectApps, updateProjectApps, bundles, subServiceLines } = this.props;
    const bundleIds = formState.typeAppBundle.fieldVal.map((bundle) => {
      return bundles.find((item) => item.name === bundle)?.id;
    });
    const appSubServiceLines = formState.typeSubServiceLines.fieldVal.map((subServiceLine) => {
      return subServiceLines.find((item) => item.name === subServiceLine)?.id;
    });
    const data: AddAppClass = {
      appCategoryId: formState.typeAppCategory.fieldVal,
      demoSiteURL: formState.typeDemoSiteURL.fieldVal,
      appImageURL: formState.typeAppImageURL.fieldVal,
      appRelativeURL: formState.typeAppRelativeURL.fieldVal,
      appPlatformClassId: formState.typeAppPlatformClassId.fieldVal,
      name: formState.typeName.fieldVal,
      description: formState.typeDesc.fieldVal,
      key: formState.typeKey.fieldVal,
      appBundleClassIds: bundleIds,
      externalAppKey: formState.typeExternalAppKey.fieldVal,
      appHotlink: formState.typeAppHotlink.fieldVal,
      subServiceLines: appSubServiceLines,
    };

    if (formState.typeAutoPublish.fieldVal === "") {
      data.autoPublish = false;
    } else {
      data.autoPublish = formState.typeAutoPublish.fieldVal;
    }

    if (formState.typeIsLimitedAvailable.fieldVal === "") {
      data.isLimitedAvailable = false;
    } else {
      data.isLimitedAvailable = formState.typeIsLimitedAvailable.fieldVal;
    }
    if (formState.typeAppOwners.length > 0) {
      data.appOwners = formState.typeAppOwners.map((appOwnerValue) => ({
        regionId: appOwnerValue.typeAppOwnersRegionId.fieldVal,
        name: appOwnerValue.typeAppOwnersName.fieldVal,
        email: appOwnerValue.typeAppOwnersEmail.fieldVal,
        ccEmail: appOwnerValue.typeAppOwnersCcEmail.fieldVal,
      }));
    }
    if (isEdit) {
      data.id = formState.typeId.fieldVal;
      updateProjectApps(data);
    } else {
      addProjectApps(data);
    }
    if (softDeleteVideo && demoPathLink) {
      this.deleteAdminAppsVideoRequest(demoPathLink, false);
    }

    if (softDeleteThumbnail && imagePathLink) {
      this.deleteAdminAppsThumbnailRequest(imagePathLink, false);
    }
    this.closeModal(false);
  };

  closeModal = (deleteFlag?) => {
    const { filePathLink, acceptedFilesDrag, softDeleteVideo, demoPathLink, thumbnailPathLink, softDeleteThumbnail, imagePathLink } =
      this.state;
    const formState = this.setFormAdminApps();
    if (softDeleteVideo) {
      formState.typeDemoSiteURL.fieldVal = demoPathLink;
      this.setState({ filePathLink: demoPathLink });
    }
    if (filePathLink !== "" && acceptedFilesDrag && deleteFlag) {
      this.deleteAdminAppsVideoRequest(filePathLink, false);
    }

    if (softDeleteThumbnail) {
      formState.typeAppImageURL.fieldVal = imagePathLink;
      this.setState({ thumbnailPathLink: imagePathLink });
    }
    if (thumbnailPathLink !== "" && acceptedFilesDrag && deleteFlag) {
      this.deleteAdminAppsThumbnailRequest(thumbnailPathLink, false);
    }

    this.setState({
      showAddOrEditModal: false,
      formState,
      acceptedFilesDrag: false,
      filePathLink: "",
      thumbnailPathLink: ""
    });
  };

  handleRemoveAppOwner = (idx: number) => {
    const { formState } = this.state;
    formState.typeAppOwners.splice(idx, 1);
    this.setState({ formState, isFormValid: this.checkDirtyFields() });
  };

  onFormChange = (val, fieldName: string, idx: number) => {
    const { formState } = this.state;

    if (_.isNumber(idx)) {
      if (fieldName === "typeAppOwnersName") {
        if (val[0]?.givenName && val[0]?.surname) {
          formState.typeAppOwners[idx][
            "typeAppOwnersName"
          ].fieldVal = `${val[0]?.givenName} ${val[0]?.surname}`;
        } else {
          formState.typeAppOwners[idx]["typeAppOwnersName"].fieldVal = "";
        }
        formState.typeAppOwners[idx]["typeAppOwnersEmail"].fieldVal = val[0]
          ?.mail
          ? val[0]?.mail
          : "";
        formState.typeAppOwners[idx]["typeAppOwnersName"].isvalid =
          val[0]?.givenName?.length;
        formState.typeAppOwners[idx]["typeAppOwnersEmail"].isvalid =
          val[0]?.mail?.length;
      } else {
        formState.typeAppOwners[idx][fieldName].fieldVal = val;
        if (this.noValidateField.indexOf(fieldName) === -1) {
          formState.typeAppOwners[idx][fieldName].isvalid = val.length;
        }
      }
    } else {
      this.handleFormChange(formState, val, fieldName);
    }

    this.setState({
      formState: formState,
      isFormValid: this.checkDirtyFields(),
    });
  };

  handleFormChange = (formState, val, fieldName) => {
    const { typeAppPlatformClassId } = formState;
    const { appPlatforms } = this.props;
    formState[fieldName].fieldVal = val;
    if (this.noValidateField.indexOf(fieldName) === -1) {
      if (fieldName === formState.typeName.fieldName) {
        formState[fieldName].isvalid = val.length >= MIN_APP_NAME_LENGTH;
      } else {
        formState[fieldName].isvalid = val.length;
      }
    }
    if (typeAppPlatformClassId.fieldVal) {
      const selectAppPlatform = _.find(appPlatforms, [
        "id",
        typeAppPlatformClassId.fieldVal,
      ]);
      if (selectAppPlatform && selectAppPlatform.key === "CE4") {
        formState.typeExternalAppKey.disable = false;
      } else {
        formState.typeExternalAppKey.disable = true;
        formState.typeExternalAppKey.fieldVal = "";
        formState.typeExternalAppKey.isvalid = true;
      }
    } else {
      formState.typeExternalAppKey.disable = true;
      formState.typeExternalAppKey.isvalid = true;
    }
  };

  checkDirtyFields = () => {
    const { formState } = this.state;
    let formValidateOwners = true;
    let filledFields = 0;
    const formValidateName =
      formState.typeName.fieldVal.length >= MIN_APP_NAME_LENGTH &&
      !validateFormInputForSpecialChar(formState.typeName.fieldVal);
    const isValidProjectDesc = !validateFormInputForSpecialChar(
      formState.typeDesc.fieldVal
    );

    const isValidTypeKey = !validateFormInputForSpecialChar(
      formState.typeKey.fieldVal
    );

    let noValidateFields = this.noValidateField.length;
    if (formState.typeAppOwners.length) {
      _.forEach(formState.typeAppOwners, (appOwner) => {
        if (
          _.isEmpty(appOwner["typeAppOwnersRegionId"]["fieldVal"]) ||
          _.isEmpty(appOwner["typeAppOwnersName"]["fieldVal"]) ||
          _.isEmpty(appOwner["typeAppOwnersEmail"]["fieldVal"]) ||
          (!_.isEmpty(appOwner["typeAppOwnersCcEmail"]["fieldVal"]) &&
            this.validateEmail(appOwner["typeAppOwnersCcEmail"]["fieldVal"]))
        ) {
          formValidateOwners = false;
          return false;
        }
      });
    }

    if (formState.typeExternalAppKey.fieldVal) {
      noValidateFields = noValidateFields - 1;
    } else if (!formState.typeExternalAppKey.disable) {
      formValidateOwners = false;
    }

    Object.entries(formState).forEach(([key]) => {
      if (
        this.noValidateField.indexOf(key) === -1 &&
        formState[key].fieldVal &&
        formState[key].fieldVal
      ) {
        filledFields++;
      }
    });
    if (this.state.isEdit) {
      return (
        filledFields + 1 === Object.keys(formState).length - noValidateFields &&
        formValidateOwners &&
        formValidateName &&
        isValidProjectDesc &&
        isValidTypeKey
      );
    } else {
      return (
        filledFields + 2 === Object.keys(formState).length - noValidateFields &&
        formValidateOwners &&
        formValidateName &&
        isValidProjectDesc &&
        isValidTypeKey
      );
    }
  };

  validateEmail = (email: string) => {
    const regex =
      //eslint-disable-next-line
      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+)+([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9\-]+\.)+([a-zA-Z0-9\-\.]+))*$/;
    return !regex.test(String(email).toLowerCase());
  };

  handleAddAppOwner = () => {
    const { formState } = this.state;
    formState.typeAppOwners.push(_.cloneDeep(this.appOwners));
    this.setState({ formState, isFormValid: this.checkDirtyFields() });
  };

  getAdminAppsVideoRequest = (payload) => {
    this.props.getAdminAppsVideo(payload);
  };

  getAdminAppsThumbnailRequest = (payload) => {
    this.props.getAdminAppsThumbnail(payload);
  };

  deleteAdminAppsVideoRequest = (payload, softdelete) => {
    const { formState } = this.state;
    formState.typeDemoSiteURL.fieldVal = "";
    this.setState({
      filePathLink: "",
      softDeleteVideo: softdelete,
      formState,
      demoPathLink: payload,
      isFormValid: this.checkDirtyFields(),
    });
    if (!softdelete) {
      const deletePayload = { filePath: payload };
      this.props.deleteAdminAppsVideo(deletePayload);
    }
  };

  deleteAdminAppsThumbnailRequest = (payload, softdelete) => {
    const { formState } = this.state;
    formState.typeAppImageURL.fieldVal = "";
    this.setState({
      thumbnailPathLink: "",
      softDeleteThumbnail: softdelete,
      formState,
      imagePathLink: payload,
      isFormValid: this.checkDirtyFields(),
    });
    if (!softdelete) {
      const deletePayload = { filePath: payload };
      this.props.deleteAdminAppsThumbnail(deletePayload);
    }
  };

  handleUpdateApp = (selectedApp) => {
    const formState = this.setFormAdminApps(selectedApp);
    this.setState({
      showAddOrEditModal: true,
      isEdit: true,
      formState,
      isFormValid: false,
    });
  };

  handleSearchChange = (query) => {
    this.props.fetchSearchData(query);
  };

  closeEditNotification = () => {
    this.setState({ showEditNotification: false });
  };

  closeAddNotification = () => {
    this.setState({ showAddNotification: false });
  };

  onEditClick = (selectedApp) => {
    const formState = this.setFormAdminApps(selectedApp);
    this.setState({
      showAddOrEditModal: true,
      isEdit: true,
      formState,
      isFormValid: false,
    });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  deleteApp = () => {
    const { selectedApp } = this.state;
    if (selectedApp && selectedApp["id"]) {
      this.props.deleteAdminAppAction(selectedApp["id"]);
    }
    this.setState({ showDeleteModal: false });
  };

  closeNotification = () => {
    this.setState({ showNotification: false });
  };

  manageDeleteNotification = (success: boolean) => {
    this.notificationVariant = success ? "success" : "error";
    this.notificationMessage = success
      ? t.admin_apps_delete_app_success
      : t.admin_apps_delete_app_error;
    this.props.flushAdminAppAction();
    this.setState({ showNotification: true });
    setTimeout(() => {
      if (this.state.showNotification) {
        this.setState({ showNotification: false });
      }
    }, 3000);
  };

  handleSearchAdminApps = (query) => {
    this.props.fetchSearchAppsData(query);
  };

  handleSelectValue = (selectedArray) => {
    this.setState({
      ...this.state,
      searchQuery: selectedArray,
    });
  };

  renderMenuItem = (option, props) => {
    return (
      <div className="async-dropdown">
        <div className="async-dropdown-name">{option.name}</div>
        <div className="async-description">{option.description}</div>
      </div>
    );
  };

  clearSearch = () => {
    if (this.typeahead) {
      this.typeahead.clear();
    }
  };

  getUserAdminConsoleAccess = () => {
    const { accessRight, sspRoles } = this.props;
    const portalRole =
      (accessRight?.portalRoles &&
        accessRight.portalRoles.length > 0 &&
        accessRight.portalRoles[0]) ||
      "";
    return getAdminConsoleAccess(portalRole, sspRoles);
  };

  handleSorting = (sortColumn = DEFAULT_SORT.colId, sortDirection = DEFAULT_SORT.sort) => {
    const { paginationPayload } = this.state;

    if (paginationPayload.sortColumn === sortColumn && paginationPayload.sortDirection === sortDirection) {
      return;
    }

    const updatedPaginationPayload = {
      ...paginationPayload,
      sortColumn,
      sortDirection,
    };
    this.setState({ paginationPayload: updatedPaginationPayload });
  };

  handleFilterChange = (
    filters,
    sortColumn = DEFAULT_SORT.colId,
    sortDirection = DEFAULT_SORT.sort
  ) => {
    const { paginationPayload } = this.state;
    const updatedPayload = {
      ...paginationPayload,
      filters,
      sortColumn,
      sortDirection,
      pageNumber: 1,
    };

    this.setState({ paginationPayload: updatedPayload });
  };

  updateFilterState = () => {
    const {
      filters = [],
      sortDirection,
      sortColumn,
    } = this.state.paginationPayload;
    const isDefaultSort =
      sortDirection === DEFAULT_SORT.sort && sortColumn === DEFAULT_SORT.colId;
    const isClearAllDisabled = isDefaultSort && !filters.length;

    this.props.updatefilterState({
      filters,
      sortDirection,
      sortColumn,
      isClearAllDisabled,
    });
  };

  renderNotification = (
    showEditNotification,
    showAddNotification,
    showNotification,
    selectedApp
  ) => (
    <>
      {showEditNotification && (
        <AppNotification
          message={`${this.notificationEditMessage}`}
          variant={this.notificationEditVariant}
          onCloseNotifcation={this.closeEditNotification}
        />
      )}
      {showAddNotification && (
        <AppNotification
          message={`${this.notificationAddMessage}`}
          variant={this.notificationAddVariant}
          onCloseNotifcation={this.closeAddNotification}
        />
      )}
      {showNotification && (
        <AppNotification
          message={`${selectedApp && selectedApp["name"]} ${this.notificationMessage
            }`}
          variant={this.notificationVariant}
          onCloseNotifcation={this.closeNotification}
        />
      )}
    </>
  );

  render() {
    const {
      applications,
      appCategories,
      appPlatforms,
      projectRegions,
      bundles,
      searchLoader,
      searchUserData,
      searchLoaderApps,
      searchAdminApps,
      paginationDetails,
      subServiceLines
    } = this.props;
    const {
      showLinkCopiedNotifiacation,
      paginationPayload,
      showAddOrEditModal,
      isEdit,
      formState,
      isFormValid,
      showEditNotification,
      showAddNotification,
      filePathLink,
      thumbnailPathLink,
      selectedApp,
      showNotification,
      showDeleteModal,
      searchQuery,
    } = this.state;
    const rowData = searchQuery.length ? searchQuery : applications;
    const currentPage = searchQuery.length ? 1 : paginationPayload.pageNumber;
    if (paginationDetails) {
      paginationPayload.totalRecords = paginationDetails.totalRecords;
      paginationPayload.totalRecordsWithoutFilter =
        paginationDetails.totalRecords ||
        paginationPayload.totalRecordsWithoutFilter;
    }
    if (searchQuery.length) {
      paginationPayload.totalRecords = searchQuery.length;
    }
    const adminConsoleAccess = this.getUserAdminConsoleAccess();
    const filteredColumns = columnConfig.filter(
      (item) => item.field !== "iconButton" || adminConsoleAccess.portalAdmin
    );

    return (
      <CrashBoundary>
        <div className="admin-apps">
          <div>
            <div className="add-apps">
              <p className="content-h4">{t.admin_portal_app_title}</p>
              {(adminConsoleAccess.portalAdmin && (
                <AppButton
                  color="primary"
                  size="medium"
                  onClick={this.handleAddApps}
                >
                  {t.admin_apps_add}
                </AppButton>
              )) ||
                null}
            </div>
            <div className="apps-count">
              {t.total_applications}
              {paginationPayload.totalRecordsWithoutFilter || 0}
            </div>
            <div className="search-wrapper">
              <div className="project-form-field">
                <AppIcon icon={search} />
                <AsyncTypeahead
                  id="async-search"
                  isLoading={searchLoaderApps}
                  filterBy={["name"]}
                  labelKey={(option) => `${option.name}`}
                  minLength={3}
                  onSearch={this.handleSearchAdminApps}
                  options={searchAdminApps}
                  onChange={(selected) => this.handleSelectValue(selected)}
                  placeholder={t.admin_apps_search_app}
                  ref={(ref) => (this.typeahead = ref)}
                  useCache={false}
                  renderMenuItemChildren={(option, props) =>
                    this.renderMenuItem(option, props)
                  }
                >
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </AsyncTypeahead>
              </div>
            </div>

            <div className={`app-table`}>
              <List
                columnConfig={filteredColumns}
                defaultColDef={defaultColDef}
                firstColumnBorderRight={true}
                rowData={rowData}
                paginate={true}
                withStickyScroll
                frameworkComponents={{
                  playVideoCellRenderer: this.renderPreviewVideoRows,
                  moreMenuCellRenderer: this.renderMoreMenu,
                  appCategoriesCellRenderer: this.renderAppCategories,
                  appDescriptionCellRender: this.renderDescriptionRows,
                  appOwnersCellRenderer: this.renderOwners,
                  appBundlesCellRenderer: this.renderAppBundles
                }}
                onGridReady={this.onGridReady}
                onSortChanged={this.handleSorting}
                currentPage={currentPage}
                pagination={
                  !!searchQuery.length
                    ? { ...paginationPayload, pageNumber: 1 }
                    : paginationPayload
                }
                setItemsPerPage={this.handlePageSizeChange}
                onPageChange={this.handlePageNumberChange}
                onFilterChange={this.handleFilterChange}
                defaultSort={[DEFAULT_SORT]}
              />
            </div>
          </div>
          {showLinkCopiedNotifiacation && (
            <span className="link-copied-notification">{t.link_copied}</span>
          )}

          <AdminAppsModal
            isOpen={showAddOrEditModal}
            isEdit={isEdit}
            handleAddOrEditApps={this.handleAddOrEditApps}
            onModalClose={this.closeModal}
            handleRemoveAppOwner={this.handleRemoveAppOwner}
            formState={formState}
            isFormValid={isFormValid}
            appCategories={appCategories}
            appBundles={bundles}
            appPlatforms={appPlatforms}
            onFormChange={this.onFormChange}
            projectRegions={projectRegions}
            handleAddAppOwner={this.handleAddAppOwner}
            getAdminAppsVideo={this.getAdminAppsVideoRequest}
            deleteAdminAppsVideo={this.deleteAdminAppsVideoRequest}
            deleteAdminAppsThumbnail={this.deleteAdminAppsThumbnailRequest}
            searchLoader={searchLoader}
            userSearchData={searchUserData}
            handleSearchChange={this.handleSearchChange}
            filePath={filePathLink}
            thumbnailPath={thumbnailPathLink}
            renderPreviewVideoRows={this.renderPreviewVideoRows}
            renderThumbnailImage={this.renderThumbnailImage}
            getAdminAppsThumbnail={this.getAdminAppsThumbnailRequest}
            subServiceLines={subServiceLines}
          >
            <div className="modal-content">
              <div className="app-title-bold">
                {`${t.delete_app_modal_content} ${t.delete_app_modal_content_app}`}
              </div>
              <div className="app-title-bold">{t.delete_app_modal_warning}</div>
            </div>
          </AdminAppsModal>
          <AppModal
            showModal={showDeleteModal}
            onModalClose={this.closeDeleteModal}
            onConfirm={this.deleteApp}
            cancelBtnText={t.cancel}
            confirmBtnText={t.delete}
            title={t.admin_apps_modal_delete_title}
          >
            <div className="modal-content deleteModal">
              <div className="app-title-bold">
                <span className="app-modal-delete-body">
                  {t.admin_apps_modal_delete_body}{" "}
                </span>
                <span className="red-highlighted">{t.delete} </span>
                <span>{selectedApp && selectedApp["name"]}? </span>
              </div>
            </div>
          </AppModal>

          {this.renderNotification(
            showEditNotification,
            showAddNotification,
            showNotification,
            selectedApp
          )}
        </div>
      </CrashBoundary>
    );
  }
}

const defaultColDef = {
  resizable: false,
  // Add other default properties that you want to apply to all columns
};
const mapStateToProps = ({
  adminProjectsReducer,
  meta,
  meReducer,
  dataLocationsReducer,
  projectAppsReducer,
  adminUsersReducer,
}) => {
  return {
    applications: adminProjectsReducer.applications,
    paginationDetails: adminProjectsReducer.paginationDetails,
    accessRight: meReducer.accessRight,
    sspRoles: meta.userRoles,
    projectRegions: dataLocationsReducer.projectRegions,
    appCategories: meta.appCategories,
    bundles: meta.bundles,
    appPlatforms: meta.appPlatforms,
    filePath: adminProjectsReducer.filePath,
    thumbnailPath: adminProjectsReducer.thumbnailPath,
    projectAppsAdd: projectAppsReducer.addProjectAppsData,
    projectAppsUpdate: projectAppsReducer.updateProjectAppsData,
    searchLoader: adminUsersReducer.searchLoader,
    searchUserData: adminUsersReducer.adminSearchUserGrid.data,
    deleteFilePath: adminProjectsReducer.deleteFilePath,
    deleteThumbnailPath: adminProjectsReducer.deleteThumbnailPath,
    deleteApp: adminProjectsReducer.deleteApp,
    searchLoaderApps: adminProjectsReducer.searchLoaderApps,
    searchAdminApps: adminProjectsReducer.searchAdminApps,
    subServiceLines: meta.subServiceLines
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdminAppsRequest: (payload) => {
      dispatch(getAdminAppsRequest(payload));
    },
    showPreviewModal: (payload) => {
      dispatch(showPreviewModal(payload));
    },
    getProjectRegions: () => {
      dispatch(getProjectRegions());
    },
    getAppCategories: () => {
      return dispatch(getAppCategories());
    },
    getBundleCards: (): BundleClass[] => {
      return dispatch(getBundles());
    },
    getAppPlatforms: () => {
      return dispatch(getAppPlatforms());
    },
    getAdminAppsVideo: (payload) => {
      dispatch(getAdminAppsVideo(payload));
    },
    getAdminAppsThumbnail: (payload) => {
      dispatch(getAdminAppsThumbnail(payload));
    },
    updateProjectApps: (payload) => {
      dispatch(updateProjectAppsAction(payload));
    },
    addProjectApps: (payload) => {
      dispatch(addProjectAppsAction(payload));
    },
    fetchSearchData: (payload) => {
      dispatch(searchAdminUserGrid(payload));
    },
    deleteAdminAppsVideo: (payload) => {
      dispatch(deleteAdminAppsVideo(payload));
    },
    deleteAdminAppsThumbnail: (payload) => {
      dispatch(deleteAdminAppsThumbnail(payload));
    },
    deleteAdminAppAction: (payload) => {
      dispatch(deleteAdminAppAction(payload));
    },
    flushAdminAppAction: () => {
      dispatch(flushAdminAppAction());
    },
    fetchSearchAppsData: (payload) => {
      dispatch(searchAdminAppsAction(payload));
    },
    updatefilterState: (payload) => {
      dispatch(updatefilterState(payload));
    },
    getSubServiceLines: () => {
      return dispatch(getSubServiceLines(null));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminApps)
);
